import { makeStyles } from "@material-ui/core/styles";
import ConsultIcon from "../../images/consult-bg.svg";

export default makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    minHeight: "60vH",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    alignSelf: "stretch",
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  waitingRoomPaper: {
    minHeight: "60vH",
    backgroundImage: `url(${ConsultIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "50% 50%",
    maxWidth: "800px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  waitingText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "60vW",
    [theme.breakpoints.down("md")]: {
      padding: "2em 0 2em",
      maxWidth: "90vW",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "690px",
    },
  },
}));
