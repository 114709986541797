import { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import Markdown from "./Markdown";
import terms from "./terms.md";
import useStyles from "./styles";
import FormCheckboxControl from "./controls/FormCheckbox";
import { useFormContext } from "react-hook-form";

// TODO fix the styles

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function TermsDialog() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [postMarkdown, setPostMarkdown] = useState("");
  const { setValue } = useFormContext();

  // load markdown from file into state
  useEffect(() => {
    fetch(terms)
      .then((response) => response.text())
      .then((text) => {
        setPostMarkdown(text);
      });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    setValue("termsCheckbox", true);
    setOpen(false);
  };

  const formLabel = () => {
    return (
      <Typography>
        Agree to our
        <Button
          color="primary"
          variant="text"
          style={{
            textTransform: "none",
          }}
          onClick={handleClickOpen}
        >
          Terms and Conditions
        </Button>
      </Typography>
    );
  };

  return (
    <>
      <FormCheckboxControl name="termsCheckbox" label={formLabel()} />

      <Dialog onClose={handleClose} aria-labelledby="terms-dialog" open={open}>
        <DialogTitle id="terms-dialog" onClose={handleClose}>
          Terms and Conditions
        </DialogTitle>
        <DialogContent dividers>
          <Markdown>{postMarkdown}</Markdown>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} autoFocus onClick={handleAgree}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
