import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import GetGreenpassButton from "../Common/GetGreenpassButton";
import Carousel from "react-material-ui-carousel";

const images = [
  {
    imageUrl:
      "https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-beach.jpg",
    title: "greenpass-medical-card-beach",
  },
  {
    imageUrl:
      "https://d3cli4pc8r7lwv.cloudfront.net/greenpass-MMJ-card-mountains.jpeg",
    title: "greenpass-MMJ-card-mountains",
  },
  {
    imageUrl:
      "https://d3cli4pc8r7lwv.cloudfront.net/greenpass-marijuana-outdoors.jpg",
    title: "greenpass-marijuana-outdoors",
  },
];

const GetGreenpass = () => {
  const classes = useStyles();
  return (
    <Carousel interval={7000} indicators={false} className={classes.carousel}>
      {images.map((image) => (
        <div
          key={image.title}
          style={{
            // TODO make this style common with About/Get Started MO
            backgroundImage: `url(${image.imageUrl})`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            minHeight: "600px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h1"
            align="center"
            style={{ color: "#FFFFFF" }}
            paragraph
          >
            Your medical marijuana <br /> journey starts here
          </Typography>
          <GetGreenpassButton />
        </div>
      ))}
    </Carousel>
  );
};

export default GetGreenpass;
