import { Container, Typography, Link } from "@material-ui/core";
import GetGreenpassButton from "../Common/GetGreenpassButton";
import useStyles from "./styles";

export default function MissouriSteps() {
  const classes = useStyles();
  return (
    <div
      style={{
        backgroundColor: "#206c54",
        display: "flex",
        justifyContent: "center",
        padding: "4em 0 4em",
      }}
    >
      <Container maxWidth="md">
        <div className={classes.stepsContainer}>
          <Typography align="left" style={{ color: "#206c54" }}>
            HOW TO
          </Typography>
          <Typography
            align="left"
            variant="h4"
            style={{ color: "#206c54", paddingBottom: "1em" }}
          >
            Get a medical card <br />
            in Missouri
          </Typography>
          <Typography variant="h6">1. SIGN UP ON OUR WEBSITE</Typography>
          <Typography style={{ paddingBottom: "1em" }}>
            Complete a quick, confidential 30 second questionnaire, about your
            medical history and wellness goals to see if you pre-qualify for a
            Missouri MMJ card.
          </Typography>
          <Typography variant="h6">2. CHAT WITH A DOCTOR ONLINE</Typography>
          <Typography style={{ paddingBottom: "1em" }}>
            If you pre-qualify, we will process your payment through our secure
            server and you can then schedule a convenient virtual consultation
            with one of our licensed physicians. Our doctors are friendly and
            knowledgeable. Ask them anything you like. Evaluations for MMJ
            usually only take about 5 minutes.
          </Typography>
          <Typography variant="h6">
            3. WAIT FOR YOUR DOCTOR TO COMPLETE YOUR RECOMMENDATION
          </Typography>
          <Typography style={{ paddingBottom: "1em" }}>
            Within 24-48 hours of your consultation, you will receive an update
            from your doctor about your status of your recommendation.
          </Typography>
          <Typography variant="h6">
            4. COMPLETE YOUR STATE REGISTRATION
          </Typography>
          <Typography style={{ paddingBottom: "1em" }}>
            Once you receive an email from your doctor with an approval
            recommendation, you can log into the{" "}
            <Link
              href="https://mo-public.mycomplia.com/#!/mo/register"
              target="_blank"
              rel="noopener noreferrer"
            >
              Missouri patient application page
            </Link>{" "}
            and complete your registration request.
          </Typography>
          <Typography variant="h6">5. CHECK YOUR EMAIL</Typography>
          <Typography style={{ paddingBottom: "1em" }}>
            The{" "}
            <Link
              href="https://health.mo.gov/safety/medical-marijuana/patient-services.php"
              target="_blank"
              rel="noopener noreferrer"
            >
              Missouri Department of Health and Senior Services
            </Link>{" "}
            will review your application within 30 days. If you are approved,
            the state will send a digital file of your patient ID card via
            email.
          </Typography>
          <Typography variant="h6">
            6. PRINT YOUR PATIENT IDENTIFICATION CARD
          </Typography>
          <Typography style={{ paddingBottom: "1em" }}>
            Just download your patient identification card via your email. The
            state doesn&apos;t send physical cards, so this is all you need. Be
            sure to print it out! Missourians will need to present a physical
            copy in order to purchase at a dispensary.
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "2em",
            }}
          >
            <GetGreenpassButton />
          </div>
        </div>
      </Container>
    </div>
  );
}
