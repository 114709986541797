import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';
import HowDidYouHear from './HowDidYouHear';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import useStyles from './styles';

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const ConsultScheduled = ({ consultDate }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant="h2" align="center" style={{ color: '#1C6F52' }}>
        Congratulations!
      </Typography>
      <Typography variant="h6" align="center" style={{ color: '#707070' }}>
        Your consultation has been booked!
      </Typography>
      <Typography
        variant="h4"
        align="center"
        style={{ paddingTop: '1em', color: '#0D2433' }}
      >
        {dayjs.utc(consultDate).local().format('MM/DD/YYYY h:mm A z')}
      </Typography>
      <Typography
        variant="h6"
        align="center"
        style={{
          padding: '1em 0 2em',
          color: '#707070',
        }}
      >
        An email has been sent to your account with this information.
      </Typography>
      <HowDidYouHear />
    </Paper>
  );
};

ConsultScheduled.propTypes = {
  consultDate: PropTypes.string,
};

export default ConsultScheduled;
