import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    background: "transparent",
    justifyContent: "space-between",
    zIndex: 100,
    position: "relative",
  },
  icon: {
    height: "100%",
    width: "100%",
  },
  profile: {
    justifyContent: "space-between",
    display: "flex",
    minWidth: "8em",
  },
  profileAvatar: {
    backgroundColor: "#2AB56E",
    marginRight: theme.spacing(1),
  },
  profileButton: {
    margin: theme.spacing(1),
    border: "2px solid #0D2433",
    borderRadius: "5em",
    "&:hover": {
      color: "#2AB56E",
    },
  },
  userName: {
    display: "flex",
    alignItems: "center",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  button: {
    color: "#FFFFFF",
    backgroundColor: "#0D2433",
    "&:hover": {
      backgroundColor: "#2AB56E",
    },
  },
}));
