import PropTypes from "prop-types";
import { TextField, Grid } from "@material-ui/core";
import { Controller } from "react-hook-form";

const FormFieldControl = ({ control, half, name, ...rest }) => {
  return (
    <Grid item xs={12} sm={half ? 6 : 12}>
      <Controller
        control={control}
        defaultValue={rest.defaultValue || ""}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <TextField
            fullWidth
            variant="outlined"
            {...field}
            {...rest}
            helperText={error ? error.message : ""}
            error={!!error}
          />
        )}
      />
    </Grid>
  );
};

export default FormFieldControl;

FormFieldControl.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  half: PropTypes.bool,
};
