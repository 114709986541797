import { Button, Link, Typography } from "@material-ui/core";
import useStyles from "./styles";
import AboutThreeSteps from "./AboutThreeSteps";

const About = () => {
  const classes = useStyles();
  return (
    <>
      <div
        style={{
          backgroundImage: `url(https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-about.jpg)`,
          backgroundPosition: "center 70%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          marginTop: "-12em",
          minHeight: "800px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100px" }}></div>
        <Typography
          style={{ paddingTop: "3em", color: "#FFFFFF" }}
          variant="h1"
        >
          Why greenpass?
        </Typography>
      </div>
      <AboutThreeSteps />
      <div className={classes.whoWeAreContainer}>
        <div style={{ maxWidth: "500px" }}>
          <img
            src="https://d3cli4pc8r7lwv.cloudfront.net/greenpass-get-a-medical-card-online.png"
            alt="Greenpass Phone"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
        <div className="invisible" style={{ width: "200px" }} />
        <div
          style={{
            maxWidth: "600px",
            display: "flex",
            flexDirection: "column",
            alignItems: "space-around",
            justifyContent: "space-around",
          }}
        >
          <Typography
            variant="h4"
            align="center"
            style={{ color: "#0D2433", paddingBottom: "2em" }}
          >
            Can I get a medical card with greenpass?
          </Typography>
          <Typography variant="h6" align="center">
            The landscape of wellness is constantly changing and you might be
            wondering - is medical cannabis legal in my state? <br />
            <br />
            Visit the greenpass blog to learn more about medical marijuana
            legislation and reform where you live - and across the country.
          </Typography>
          <div
            style={{
              paddingTop: "2em",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link
              href="https://blog.greenpassmed.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
              }}
            >
              <Button variant="contained">blog</Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
