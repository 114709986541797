import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ErrorBox from '../Error/ErrorBox';
import PaymentContext from '../../contexts/PaymentContext';
import Discount from './Discount';
import { Grid, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { selectUserState } from '../../redux/slices/userSlice';
import PrequalifiedTooltip from '../Common/PrequalifiedTooltip';

import OrderSummary from './OrderSummary';

export default function PaymentForm() {
  const { setCard } = useContext(PaymentContext);
  const user = useSelector(selectUserState);
  const [loadError, setLoadError] = useState(false);
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const appId = process.env.REACT_APP_SQ_APPLICATION_ID;
  const locationId = process.env.REACT_APP_SQ_LOCATION_ID;

  useEffect(() => {
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }

    if (user.paymentSuccess) {
      return;
    }

    let payments;
    try {
      payments = window.Square.payments(appId, locationId);
    } catch (e) {
      console.error('Initializing Square failed', e);
      setLoadError(true);
      return;
    }

    async function initialize(payments) {
      try {
        const card = await payments.card();
        await card.attach('#card-container');
        setCard(card);
      } catch (e) {
        console.error('Initializing Card failed', e);
        return;
      }
    }

    initialize(payments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        align="center"
        style={{ color: '#1C6F52' }}
        paragraph
      >
        Payment Details
      </Typography>
      <>
        {user.paymentSuccess ? (
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            paragraph
          >
            Thank you for making your payment!
          </Typography>
        ) : (
          <>
            <Typography align="left" style={{ paddingBottom: '1em' }}>
              You&apos;re almost there! You&apos;ve been pre-qualified for a
              medical cannabis recommendation based on the information you
              provided.
              <PrequalifiedTooltip />
              <br />
              <br />
              Please enter payment information below to schedule a virtual
              appointment with a registered physician.
              <br />
              <br />
              Your payment will not be fully processed{' '}
              <b>unless you are approved and recommended</b> for medical
              cannabis.
              <br />
              <br />
            </Typography>
            <form id="payment-form">
              <div id="card-container"></div>
            </form>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
              direction={mediumScreen ? 'row' : 'column'}
              style={{ padding: mediumScreen ? '2em 0 2em' : '0 0 1em' }}
            >
              <Grid xs={12} sm={5} item>
                <Discount
                  stateForApplication={user.stateForApplication}
                  locationId={locationId}
                />
              </Grid>
              <Grid
                xs={12}
                sm={7}
                container
                justifyContent="center"
                direction="column"
                item
                style={{
                  maxWidth: mediumScreen ? '100%' : '90%',
                  padding: '1em',
                  // background: "#EFEDEA",
                  // borderRadius: ".5em",
                  // border: "1px solid #1C6F52",
                }}
              >
                <OrderSummary />
              </Grid>
            </Grid>
            {loadError && (
              <ErrorBox
                errorMsg="There was an issue loading our payment provider. Please try again later."
                clearErrorMsg={null}
              />
            )}
          </>
        )}
      </>
    </>
  );
}
