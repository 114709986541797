import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  footer: {
    backgroundColor: "#0D2433",
    paddingTop: "2em",
    paddingBottom: "2em",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
      justifyContent: "center",
    },
  },
  footerGrid: {
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
      justifyContent: "center",
    },
  },
  link: {
    color: "#FFFFFF !important",
    fontFamily: "Open Sans",
  },
}));
