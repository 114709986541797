import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  signinPaper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: '200px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#2AB56E',
    '&:hover': {
      backgroundColor: '#0D2433',
    },
  },
  signinButton: {
    minHeight: '50px',
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#2AB56E',
    '&:hover': {
      backgroundColor: '#0D2433',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dateButton: {
    width: '85px',
    color: '#FFFF',
    backgroundColor: '#2AB56E',
    '&:hover': {
      color: '#FFFF',
      backgroundColor: '#0D2433',
    },
    '&:focus': {
      color: '#FFFF',
      backgroundColor: '#0D2433',
    },
  },
  datePicker: {
    paddingBottom: '1rem',
  },
  scheduleContainer: {
    width: '100%',
    paddingTop: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
