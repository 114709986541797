const signupModel = {
  formField: {
    firstName: {
      name: "firstName",
      label: "First name",
      requiredErrorMsg: "First name is required",
    },
    lastName: {
      name: "lastName",
      label: "Last name",
      requiredErrorMsg: "Last name is required",
    },
    email: {
      name: "email",
      label: "Email",
      requiredErrorMsg: "Email is required",
    },
    password: {
      name: "password",
      label: "Password",
      requiredErrorMsg: "Password is required.",
      invalidErrorMsg:
        "Password must be at least eight characters, contain at least one uppercase letter and at least one number.",
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm password",
      requiredErrorMsg: "Please confirm your password.",
      invalidErrorMsg: "Passwords must match.",
    },
  },
};

export default signupModel;
