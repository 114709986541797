import PropTypes from "prop-types";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormHelperText,
  Grid,
} from "@material-ui/core";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { useWatch, useController } from "react-hook-form";

const CheckboxGroup = ({ config, control, label, name, options, ...rest }) => {
  const {
    field: { ref, value, onChange, ...inputProps },
    formState: { errors },
  } = useController({
    name,
    control,
    defaultValue: [],
  });

  const checkboxIds = useWatch({ control, name: name }) || [];

  const handleChange = (value) => {
    const newArray = [...checkboxIds];
    const item = value;

    //Ensure array isnt empty
    if (newArray.length > 0) {
      //Attempt to find an item in array with matching id
      const index = newArray.findIndex((x) => x === item);

      // If theres no match add item to the array
      if (index === -1) {
        newArray.push(item);
      } else {
        //If there is a match and the value is empty, remove the item from the array
        newArray.splice(index, 1);
      }
    } else {
      //If the array is empty, add the item to the array
      newArray.push(item);
    }

    //Overwrite existing array with newArray}
    onChange(newArray);
  };

  return (
    <>
      <FormControl
        error={!!errors?.[name]}
        required
        style={{ padding: "1em 0 1em" }}
      >
        <FormLabel component="legend" style={{ paddingBottom: "1em" }}>
          {label}
        </FormLabel>
        <FormGroup>
          <Grid container>
            {options.map((option) => (
              <Grid item xs={12} sm={6} key={option[config.name]}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value?.some(
                        (checked) => checked === option[config.label]
                      )}
                      {...inputProps}
                      inputRef={ref}
                      onChange={() => handleChange(option[config.label])}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled color="primary" />}
                    />
                  }
                  label={option[config.label]}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </FormControl>
      <FormHelperText
        error
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {errors?.[name]?.message || ""}
      </FormHelperText>
    </>
  );
};

CheckboxGroup.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,

  config: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
  }),
};

CheckboxGroup.defaultProps = {
  config: {
    label: "label",
    name: "name",
  },
};

export default CheckboxGroup;
