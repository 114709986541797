import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  missouriIcon: {
    padding: "5em 0 1em",
    height: "150px",
    width: "150px",
    [theme.breakpoints.down("xs")]: {
      height: "100px", // TODO better way to do this
      width: "100px",
    },
  },
  stepsContainer: {
    padding: "4em 4em",
    backgroundColor: "#FFFFFF",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      padding: "2em 2em",
    },
  },
}));
