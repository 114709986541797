import { createTheme } from "@material-ui/core/styles";
import { responsiveFontSizes } from "@material-ui/core/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#2AB56E",
    },
    secondary: {
      main: "#0D2433",
    },
  },
  typography: {
    fontFamily: ["Open Sans"].join(","),
    h1: { fontSize: "4.5rem", fontFamily: ["Tuna"].join(",") },
    h2: { fontSize: "2.5rem", fontFamily: ["Tuna"].join(",") },
    h3: { fontSize: "2rem", fontFamily: ["Tuna"].join(",") },
    h4: { fontFamily: ["Tuna"].join(",") },
    h5: { fontFamily: ["Tuna"].join(",") },
    h6: { fontFamily: ["Tuna"].join(",") },
    subtitle1: { fontFamily: ["Tuna"].join(",") },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      contained: {
        // Some CSS
        margin: "1em",
        borderRadius: "5em",
        color: "white",
        minHeight: "3em",
        minWidth: "10em",
        backgroundColor: "#2AB56E",
        "&:hover": {
          backgroundColor: "#0D2433",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);
export default theme;
