import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./ScrollToTop";
import Routes from "./Routes";
import { autoLogin, selectUserState } from "./redux/slices/userSlice";

import useStyles from "./styles";
import theme from "./styles/theme";

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(selectUserState);

  useEffect(() => {
    const loginUser = async () => {
      dispatch(autoLogin());
    };
    loginUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Header />
        <ScrollToTop />
        <Routes />
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default App;
