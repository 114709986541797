import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { selectUserState } from '../../redux/slices/userSlice';
import * as api from '../../api/index.js';
import ErrorBox from '../Error/ErrorBox';

import useStyles from './styles';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear.js';

dayjs.extend(dayOfYear);

const ScheduleConsultForm = (props) => {
  const {
    formField: { consultDateTime },
  } = props;
  const classes = useStyles();
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const [selectedDate, setSelectedDate] = useState(null);
  const [timesByDate, setTimesByDate] = useState([]);
  const [dates, setDates] = useState([]);
  const [consultDaysOfYear, setConsultDaysOfYear] = useState([]);
  const [providerId, setProviderId] = useState();
  const [loading, setLoading] = useState(false);
  const [retrieveScheduleError, setRetrieveScheduleError] = useState();
  const user = useSelector(selectUserState);

  function getFilteredDates(selectedDate, dates) {
    return dates.filter((date) => {
      return dayjs.utc(date).local().isSame(selectedDate, 'day');
    });
  }

  useEffect(() => {
    setLoading(true);
    async function fetchSchedule() {
      try {
        const request = {
          firstName: user.firstName,
          lastName: user.lastName,
          portalMemberId: user.portalMemberId,
          stateForApplication: user.stateForApplication,
          userTimezone: dayjs.tz.guess(),
        };
        const { data } = await api.getScheduleAptTimes(request);
        setDates(data.dates);
        setConsultDaysOfYear(data.consultDaysOfYear);
        setSelectedDate(data.defaultConsultDate);
        setProviderId(data.providerId);
        setTimesByDate(getFilteredDates(data.defaultConsultDate, data.dates));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.data) {
          setRetrieveScheduleError(error.response.data);
        } else {
          setRetrieveScheduleError('An unexpected error has occurred');
        }
      }
    }
    fetchSchedule();
  }, [
    user.firstName,
    user.lastName,
    user.portalMemberId,
    user.stateForApplication,
  ]);

  function disableDates(date) {
    return !consultDaysOfYear.includes(dayjs(date).dayOfYear());
  }

  const handleDateChange = (selectedDate) => {
    setTimesByDate([]);
    setSelectedDate(selectedDate);
    setTimesByDate(getFilteredDates(selectedDate, dates));
  };

  const handleDateTimeSelection = (e) => {
    setValue(consultDateTime.name, e.currentTarget.value);
    // Probably a better way to do this
    setValue('providerId', providerId);
  };

  return (
    <>
      <Typography
        variant="h4"
        align="center"
        paragraph
        style={{ color: '#1C6F52' }}
      >
        Schedule A Consult
      </Typography>
      <Container className={classes.scheduleContainer}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <KeyboardDatePicker
              autoOk
              className={classes.datePicker}
              required
              id="apptdate"
              variant="inline"
              inputVariant="outlined"
              label="Appointment Date"
              format="MM/DD/YYYY"
              value={selectedDate}
              minutesStep="30"
              shouldDisableDate={disableDates}
              InputAdornmentProps={{ position: 'end' }}
              disabled={!!retrieveScheduleError}
              onChange={(date) => handleDateChange(date)}
            />
            {timesByDate.length ? (
              <Grid
                container
                direction="row"
                style={{ maxWidth: '300px', paddingBottom: '1em' }}
                spacing={1}
              >
                {timesByDate.map((time) => (
                  <Grid item xs={4} key={time}>
                    <Button
                      className={classes.dateButton}
                      value={time}
                      onClick={handleDateTimeSelection}
                    >
                      {/* Display UTC time in a localized hour:min format */}
                      {dayjs.utc(time).local().format('h:mm A')}
                    </Button>
                  </Grid>
                ))}
                {!!errors.consultDateTime && (
                  <FormHelperText error>
                    Please select a date/time.
                  </FormHelperText>
                )}
              </Grid>
            ) : (
              <Typography align="center" variant="body1" gutterBottom>
                No appointments found, <br />
                please select another date.
              </Typography>
            )}
          </>
        )}
      </Container>
      {retrieveScheduleError && (
        <ErrorBox errorMsg="Sorry, we're having trouble accessing our schedule. Please try again later or call our support team to schedule your consult at 866-633-4672." />
      )}
    </>
  );
};

ScheduleConsultForm.propTypes = {
  formField: PropTypes.shape({
    consultDateTime: PropTypes.object,
  }),
};

export default ScheduleConsultForm;
