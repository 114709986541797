import { Typography, Link } from '@material-ui/core';

export default function OhioSteps() {
  return (
    <>
      <Typography variant="h6">1. REGISTER ONLINE WITH OMMCP</Typography>
      <Typography style={{ paddingBottom: '1em' }}>
        First,{' '}
        <Link
          href="https://www.ohiomedicalmarijuanaregistry.com/logins/sign_in"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ohio patient application page
        </Link>{' '}
        in the state of Ohio. In the next 48 hours, your doctor&apos;s
        recommendation will be connected to your registration based on your name
        and social security number. If your recommendation does not appear,
        please check back later.
      </Typography>
      <Typography variant="h6">2. CHECK YOUR EMAIL</Typography>
      <Typography style={{ paddingBottom: '1em' }}>
        The{' '}
        <Link
          href="https://medicalmarijuana.ohio.gov/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ohio Medical Marijuana Control Program
        </Link>{' '}
        will approve or deny your application within 30 days. If you are
        approved, the state will send a digital file of your patient ID card via
        email.
      </Typography>
      <Typography variant="h6">3. PRINT YOUR PATIENT ID CARD</Typography>
      <Typography style={{ paddingBottom: '1em' }}>
        Be sure to print it out! Ohioans will need to present a physical copy in
        order to purchase medical marijuana at a dispensary.
      </Typography>
    </>
  );
}
