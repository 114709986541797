import PropTypes from 'prop-types';
import { Button, Container, Link, Typography } from '@material-ui/core';

import SignupIcon from '../../images/signup-icon-line.svg';
import ConsultIcon from '../../images/consult-icon-line.svg';
import PassIcon from '../../images/pass-icon-line.svg';
import useStyles from './styles';

const cards = [
  {
    title: 'SIGN UP',
    icon: SignupIcon,
    text: 'Fill out a simple form about yourself and your medical history to see if you pre-qualify.',
  },
  {
    title: 'SPEAK WITH A DOCTOR',
    icon: ConsultIcon,
    text: 'Schedule a virtual consultation with one of our licensed physicians who can give you a recommendation.',
  },
  {
    title: 'GET RELIEF',
    icon: PassIcon,
    text: 'With greenpass, you are on your way to a new wellness lifestyle.',
  },
];

const getHeaderText = (state) => {
  let price;
  switch (state) {
    case 'CA':
      price = 99;
      break;
    case 'GA':
      price = 129;
      break;
    case 'LA':
      price = 149;
      break;
    case 'MO':
      price = 99;
      break;
    case 'OH':
      price = 125;
      break;
    case 'OK':
      price = 99;
      break;
    case 'PA':
      price = 129;
      break;
    default:
      return 'Schedule an appointment for your medical card recommendation today';
  }

  return `Get a medical card recommendation online for only $${price}`;
};

const ThreeSteps = ({ state }) => {
  const classes = useStyles();
  return (
    <div className={classes.threeStepsBox}>
      <Container maxWidth="md">
        <Typography
          variant="h2"
          align="center"
          style={{ color: '#0D2433', padding: '1em 0 1em' }}
        >
          {getHeaderText(state)}
        </Typography>
        <div className={classes.threeStepsCardGrid}>
          {cards.map((card) => (
            <div key={card.title} className={classes.threeStepsGrid}>
              <div className={classes.threeStepsImageBox}>
                <img
                  src={card.icon}
                  alt={card.title}
                  className={classes.threeStepsImage}
                />
              </div>
              <Typography
                variant="h6"
                component="h2"
                style={{ padding: '1em 0 .5em' }}
              >
                {card.title}
              </Typography>
              <Typography className={classes.threeStepsCardText}>
                {card.text}
              </Typography>
            </div>
          ))}
        </div>
        <Typography
          variant="h3"
          align="center"
          style={{ color: '#0D2433', padding: '2em 0 1em' }}
        >
          Connect with a doctor from your phone, laptop, or tablet
        </Typography>
        <Typography
          variant="h5"
          align="center"
          style={{ color: '#0D2433', paddingBottom: '1em' }}
        >
          greenpass provides more than just a doctor&apos;s consultation.
          greenpass is powered by our physician&ndash;founded digital health
          network that provides innovative care solutions to patients worldwide.
          This network integrates the most sophisticated technological platform
          to patients in a variety of different settings.
          <br />
          <br />
          Through this independent partner network of the world&apos;s most
          outstanding specialty physicians, greenpass connects you directly with
          expert telemedicine services in this emerging field of medicine.
          greenpass connects you to a network of practitioners with your best
          interests at heart, and to a community of individuals like yourself
          seeking relief.
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Link
            href="https://blog.greenpassmed.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Button variant="contained">blog</Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

ThreeSteps.propTypes = {
  state: PropTypes.string,
};

export default ThreeSteps;
