import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import * as api from '../../api';
import {
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PaymentContext from '../../contexts/PaymentContext.js';

const Discount = ({ stateForApplication, locationId }) => {
  const { setDiscount, setTotal } = useContext(PaymentContext);
  const [discountCode, setDiscountCode] = useState('');
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discountLoading, setDiscountLoading] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onKeyPress = async (e) => {
    if (e.key === 'Enter') {
      validateDiscount(e);
    }
  };

  const validateDiscount = async (e) => {
    e.preventDefault();
    setError(false);

    // stops duplicate apply
    if (discountApplied) {
      return;
    }

    try {
      setDiscountLoading(true);
      const { data } = await api.calculateOrder({
        discountName: discountCode,
        locationId,
        state: stateForApplication,
      });

      setDiscount(data.order.discount);
      setTotal(data.order.total);

      setOpen(true);
      setDiscountLoading(false);
      setDiscountApplied(true);
    } catch (error) {
      setError(true);
      setDiscountLoading(false);
    }
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Grid container direction="column" alignItems="flex-end">
        <TextField
          id="discountCode"
          label="Discount Code"
          name="discountCode"
          value={discountCode}
          onInput={(e) => setDiscountCode(e.target.value)}
          onChange={() => {
            if (error) {
              setError(false);
            }
          }}
          onKeyPress={onKeyPress}
          variant="outlined"
          error={error}
          helperText={error && 'Discount not found or is no longer active.'}
        />
        <Typography />
        {discountLoading ? (
          <div
            style={{
              paddingTop: '1em',
              alignSelf: 'center',
              justifySelf: 'center',
            }}
          >
            <CircularProgress size={16} />
          </div>
        ) : (
          <Button
            size="small"
            variant="text"
            color="secondary"
            style={{
              textTransform: 'none',
              textDecoration: 'underline',
            }}
            onClick={validateDiscount}
          >
            Apply Discount Code
          </Button>
        )}
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="success"
          style={{ background: '#2AB56E' }}
        >
          Discount applied successfully!
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

Discount.propTypes = {
  locationId: PropTypes.string,
  stateForApplication: PropTypes.string,
};

export default Discount;
