import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import * as api from '../../api/index';
import { createSlice } from '@reduxjs/toolkit';
import { getErrorMessage } from '../../utils/helpers';

dayjs.extend(timezone);

const initialState = {
  id: null,
  token: null,
  portalMemberId: null,
  emailVerified: false,
  consultId: null,
  consultStatus: null,
  paymentSuccess: false,
  providerId: null,
  error: null,
  firstName: '',
  lastName: '',
  stateForApplication: null,
  loading: false,
  formLoading: false,
  howDidYouHear: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.loading = false;
      state.error = null;
      Object.assign(state, action.payload);
      // state.portalMemberId = action.payload.portalMemberId;
      // state.emailVerified = action.payload.emailVerified;
      // state.consultId = action.payload.consultId;
      // state.consultStatus = action.payload.consultStatus;
      // state.paymentSuccess = action.payload.paymentSuccess;
      // state.firstName = action.payload.firstName;
      // state.lastName = action.payload.lastName;
      // state.email = action.payload.email;
    },
    removeUser: (state) => {
      state.id = null;
      state.token = null;
      state.portalMemberId = null;
      state.emailVerified = false;
      state.consultId = null;
      state.consultStatus = null;
      state.paymentSuccess = false;
      state.firstName = '';
      state.lastName = '';
      state.email = null;
    },
    setPortalUser: (state, action) => {
      state.formLoading = false;
      state.error = null;
      state.portalMemberId = action.payload.portalMemberId;
    },
    setUserConsultData: (state, action) => {
      state.formLoading = false;
      state.error = null;
      state.consultId = action.payload.consultId;
      state.consultStatus = action.payload.consultStatus;
    },
    setPaymentSuccess: (state) => {
      state.formLoading = false;
      state.error = null;
      state.paymentSuccess = true;
    },
    setUserLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setFormLoading: (state) => {
      state.formLoading = true;
      state.error = null;
    },
    setUserError: (state, action) => {
      state.loading = false;
      state.formLoading = false;
      state.error = action.payload;
    },
    clearUserError: (state) => {
      state.error = null;
    },
    setStateForApplication: (state, action) => {
      state.stateForApplication = action.payload;
    },
    setProfileData: (state, action) => {
      // TODO remove this
      state.loading = false;
      state.error = null;
      state.user.email = action.payload.email || state.user.email;
      state.user.firstName = action.payload.firstName || state.user.firstName;
      state.user.lastName = action.payload.lastName || state.user.lastName;
    },
  },
});

export const {
  setUser,
  setPortalUser,
  setUserConsultData,
  removeUser,
  setPaymentSuccess,
  setUserLoading,
  setFormLoading,
  setUserError,
  clearUserError,
  setProfileData,
  setStateForApplication,
} = userSlice.actions;

export const autoLogin = () => async (dispatch) => {
  const gpItem = localStorage.getItem('greenpassUser');
  if (gpItem) {
    const gpUser = JSON.parse(gpItem);
    try {
      dispatch(setUserLoading());
      const { data } = await api.fetchUser(gpUser.id);
      dispatch(setUser(data));
    } catch (e) {
      localStorage.removeItem('greenpassUser');
    }
  }
};

export const setApplicationState = (state) => async (dispatch) => {
  dispatch(setStateForApplication(state));
};

export const portalEnrollUser = (formData, user) => async (dispatch) => {
  try {
    dispatch(setFormLoading());
    const enrollData = getUserEnrollData(formData, user);
    const response = await api.portalEnroll(enrollData);
    const enrollPayload = {
      portalMemberId: response.data.portalMemberId,
    };
    dispatch(setPortalUser(enrollPayload));
    return true;
  } catch (e) {
    dispatch(setUserError(getErrorMessage(e)));
    return false;
  }
};

export const scheduleConsultForMember =
  (formData, user) => async (dispatch) => {
    try {
      dispatch(setFormLoading());
      const { data } = await api.scheduleConsultForMember(
        getUserConsultData(formData, user)
      );
      dispatch(setUserConsultData(data));
      return true;
    } catch (e) {
      dispatch(setUserError(getErrorMessage(e)));
    }
  };

export const login = (credentials) => async (dispatch) => {
  try {
    dispatch(setUserLoading());
    const { data } = await api.signIn(credentials);
    dispatch(setUser(data));
    localStorage.setItem('greenpassUser', JSON.stringify(data));
  } catch (e) {
    dispatch(setUserError(getErrorMessage(e)));
  }
};

export const signup = (formData) => async (dispatch) => {
  try {
    dispatch(setUserLoading());
    const { data } = await api.signUp(formData);
    dispatch(setUser(data));
    localStorage.setItem('greenpassUser', JSON.stringify(data));
  } catch (e) {
    dispatch(setUserError(getErrorMessage(e)));
  }
};

export const logout = () => (dispatch) => {
  dispatch(removeUser());
  localStorage.clear();
};

// TODO remove this probably
export const updateUserProfile = (userData) => async (dispatch) => {
  try {
    dispatch(setUserLoading());
    const { data } = await api.updateUser(userData);
    dispatch(setProfileData(data));
  } catch (e) {
    dispatch(setUserError(getErrorMessage(e)));
  }
};

//TODO Move this to its own slice
export const payForConsult =
  (card, discountName, email, state) => async (dispatch) => {
    try {
      const locationId = process.env.REACT_APP_SQ_LOCATION_ID;
      // disable submit button while awaiting tokenization and making a payment request.
      dispatch(setFormLoading());
      const token = await tokenize(card);
      const body = {
        discountName,
        email: email,
        locationId,
        sourceId: token,
        state,
      };
      await api.payForConsult(body);
      dispatch(setPaymentSuccess());
      return true;
    } catch (e) {
      dispatch(setUserError(getErrorMessage(e)));
    }
  };

function getUserConsultData(formData, user) {
  let consultData = {
    firstName: user.firstName,
    lastName: user.lastName,
    portalMemberId: user.portalMemberId,
    knownAllergies: formData.knownAllergies,
    preexistingConditions: formData.preexistingConditions,
    currentMedications: formData.currentMedications,
    qualifyingConditions: formData.qualifyingConditions,
    stateForApplication: user.stateForApplication,
  };

  // Merge if scheduling for future date/time
  if (formData.consultDateTime) {
    const meetLaterDetails = {
      consultDateTimeISO: formData.consultDateTime,
      userTimezone: dayjs.tz.guess(),
      providerId: formData.providerId,
    };
    Object.assign(consultData, meetLaterDetails);
  }

  return consultData;
}

function formatPhoneNumber(num) {
  // strip formatting
  return num.replace(/\D+/g, '');
}

function formatBirthdate(date) {
  return dayjs(date).format('MM/DD/YYYY');
}

function getUserEnrollData(data, user) {
  return {
    fn: user.firstName,
    mn: data.middleName,
    ln: user.lastName,
    gndr: data.sex,
    db: formatBirthdate(data.birthDate),
    cty: data.city,
    addr: data.address,
    rgnrs: data.stateOfResidence,
    zp: data.zip,
    ctry: 'US',
    ph: formatPhoneNumber(data.phoneNumber),
    cc: 1, // hardcoded to US for country code (needed for ph)
    eml: user.email,
    ssn: data.ssn,
    tg3: data.licenseId, // add license ID as member tag for MediOrbis
    mrel: 0, // Policy Member relationship (Self=0, Spouse=1, Child=2) - hardcoded to 0
    st: 9, // Member status in system (Inactive=0, Active=9) - hardcoded to 9
    stateForApplication: data.stateForApplication,
  };
}

async function tokenize(paymentMethod) {
  const tokenResult = await paymentMethod.tokenize();
  if (tokenResult.status === 'OK') {
    return tokenResult.token;
  } else {
    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
    if (tokenResult.errors) {
      errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
    }
    throw new Error(errorMessage);
  }
}

export const selectUserState = (state) => state.user;

export default userSlice.reducer;
