import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Controller } from "react-hook-form";

const FormDateTimeControl = ({ control, half, name, ...rest }) => {
  return (
    <Grid item xs={12} sm={half ? 6 : 12}>
      <Controller
        control={control}
        defaultValue={null}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <KeyboardDatePicker
            {...rest}
            variant="inline"
            inputVariant="outlined"
            value={field.value}
            onChange={(date) => field.onChange(date)}
            error={!!error}
            invalidDateMessage={error ? error.message : ""}
            helperText={error ? error.message : ""}
          />
        )}
      />
    </Grid>
  );
};

export default FormDateTimeControl;

FormDateTimeControl.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  half: PropTypes.bool,
};
