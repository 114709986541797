import { Container, Typography, Link } from '@material-ui/core';
import GetGreenpassButton from '../Common/GetGreenpassButton';
import useStyles from './styles';

export default function OhioSteps() {
  const classes = useStyles();
  return (
    <div
      style={{
        backgroundColor: '#206c54',
        display: 'flex',
        justifyContent: 'center',
        padding: '4em 0 4em',
      }}
    >
      <Container maxWidth="md">
        <div className={classes.stepsContainer}>
          <Typography align="left" style={{ color: '#206c54' }}>
            HOW TO
          </Typography>
          <Typography
            align="left"
            variant="h4"
            style={{ color: '#206c54', paddingBottom: '1em' }}
          >
            Get a medical card <br />
            in Ohio
          </Typography>
          <Typography variant="h6">1. SIGN UP ON OUR WEBSITE</Typography>
          <Typography style={{ paddingBottom: '1em' }}>
            Complete a quick, confidential 30 second questionnaire, about your
            medical history and wellness goals to see if you pre-qualify for a
            Ohio MMJ card.
          </Typography>
          <Typography variant="h6">2. CHAT WITH A DOCTOR ONLINE</Typography>
          <Typography style={{ paddingBottom: '1em' }}>
            If you pre-qualify, we will process your payment through our secure
            server and you can then schedule a convenient virtual consultation
            with one of our licensed physicians. Our doctors are friendly and
            knowledgeable. Ask them anything you like. Evaluations for MMJ
            usually only take about 5 minutes.
          </Typography>
          <Typography variant="h6">
            3. WAIT FOR YOUR DOCTOR TO COMPLETE YOUR RECOMMENDATION
          </Typography>
          <Typography style={{ paddingBottom: '1em' }}>
            You will receive your certification to your email within 24 to 48
            hours after you&apos;re approved as their patient.
          </Typography>
          <Typography variant="h6">
            4. COMPLETE YOUR STATE REGISTRATION
          </Typography>
          <Typography style={{ paddingBottom: '1em' }}>
            Use the link provided in that email to log into the{' '}
            <Link
              href="https://www.ohiomedicalmarijuanaregistry.com/logins/sign_in"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ohio patient application page
            </Link>{' '}
            and complete your registration request. Your greenpass doctor&apos;s
            recommendation will be automatically connected to your registration
            based on your name and social security number within 24 hours of
            completing your consult.
          </Typography>
          <Typography variant="h6">5. CHECK YOUR EMAIL</Typography>
          <Typography style={{ paddingBottom: '1em' }}>
            The{' '}
            <Link
              href="https://medicalmarijuana.ohio.gov/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ohio Medical Marijuana Control Program
            </Link>{' '}
            will review your application. If you are approved, the state will
            send a digital file of your temporary patient ID card via email.
          </Typography>
          <Typography variant="h6">
            6. PRINT YOUR PATIENT IDENTIFICATION CARD
          </Typography>
          <Typography style={{ paddingBottom: '1em' }}>
            The state will send you a physical card, so this is all you need.
            Ohioans will need to present a physical copy in order to purchase at
            a dispensary.
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '2em',
            }}
          >
            <GetGreenpassButton />
          </div>
        </div>
      </Container>
    </div>
  );
}
