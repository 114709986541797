import GetGreenpass from "./GetGreenpass";
import WhoWeAre from "./WhoWeAre";
import ThreeSteps from "./ThreeSteps";
import ContactAccordion from "./ContactAccordion";
import { Container, Typography } from "@material-ui/core";

const Home = () => {
  return (
    <>
      <GetGreenpass />
      <ThreeSteps />
      <WhoWeAre />
      <Container maxWidth="md" style={{ paddingBottom: "2em" }}>
        <Typography variant="h3" align="center">
          Frequently Asked Questions
        </Typography>
        <ContactAccordion />
      </Container>
    </>
  );
};

export default Home;
