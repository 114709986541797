import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import GetGreenpassState from './GetGreenpassState';
import ThreeSteps from '../Home/ThreeSteps';
import CaliforniaSteps from './CaliforniaSteps';
import GeorgiaSteps from './GeorgiaSteps';
import LouisianaSteps from './LouisianaSteps';
import MissouriSteps from './MissouriSteps';
import OhioSteps from './OhioSteps';
import OklahomaSteps from './OklahomaSteps';
import PennsylvaniaSteps from './PennsylvaniaSteps';
import { setApplicationState } from '../../redux/slices/userSlice';

const Home = ({ state }) => {
  function getStateSteps() {
    switch (state) {
      case 'CA':
        return <CaliforniaSteps state={state} />;
      case 'GA':
        return <GeorgiaSteps state={state} />;
      case 'LA':
        return <LouisianaSteps state={state} />;
      case 'MO':
        return <MissouriSteps state={state} />;
      case 'OH':
        return <OhioSteps state={state} />;
      case 'OK':
        return <OklahomaSteps state={state} />;
      case 'PA':
        return <PennsylvaniaSteps state={state} />;
      default:
        break;
    }
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setApplicationState(state));
  }, [dispatch, state]);

  return (
    <>
      <GetGreenpassState state={state} />
      <ThreeSteps state={state} />
      {getStateSteps()}
    </>
  );
};

Home.propTypes = {
  state: PropTypes.string.isRequired,
};

export default Home;
