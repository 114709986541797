import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const accordionContent = [
  {
    summary: 'Where is greenpass available?',
    details:
      'Currently, greenpass is available in California, Georgia, Louisiana, Missouri, Ohio, Oklahoma, and Pennsylvania. We are actively working to expand coverage to more states.',
  },
  {
    summary:
      'How do I pre-qualify for a medical marijuana card through greenpass?',
    details:
      'Sign-up with greenpass and answer a few brief questions about your medical history and your wellness goals. If, based on the information you provide, we believe you may be eligible for a medical marijuana card, you will be matched with one of our physicians who can complete your recommendation for medical marijuana. Read more about the qualifying medical conditions below.',
  },
  {
    summary:
      'What conditions qualify for a medical marijuana card in California?',
    details:
      'In order to qualify for a medical marijuana card, Californians must meet with a licensed California physician to be diagnosed with one or more of the following conditions:',
    bullets: [
      'Chronic pain',
      'Arthritis',
      'Migraine',
      'Seizures',
      'Cancer',
      'Persistent muscle spasms',
      'Glaucoma',
      'Cachexia (Wasting Syndrome)',
      'Severe nausea',
      'HIV/AIDS',
      'Anorexia',
      "Any type of chronic or ongoing medical symptom that either:\n \
(A) Substantially limits the ability of the person to conduct one or more major life activities\n \
(B) If not alleviated, may cause serious harm to the patient's safety or physical or mental health.",
    ],
  },
  {
    summary: 'What conditions qualify for a medical marijuana card in Georgia?',
    details:
      'In order to qualify for a medical marijuana card, Georgians must meet with a licensed Georgia physician to be diagnosed with one or more of the following conditions:',
    bullets: [
      'Intractable pain',
      'Epidermolysis bullosa',
      "Crohn's disease",
      "Parkinson's disease*",
      'Multiple sclerosis*',
      'Peripheral neuropathy*',
      "Tourette's syndrome*",
      'Autism spectrum disorder, when (a) patient is 18 years of age or more, or (b) patient is less than 18 years of age and diagnosed with severe autism',
      'Seizure disorders related to diagnosis of epilepsy or trauma related head injuries',
      'Post-traumatic stress disorder (PTSD) resulting from direct exposure to or witnessing of a trauma for a patient who is at least 18 years of age',
      'Cancer* when treatment produces related wasting illness or recalcitrant nausea and vomiting',
      'Sickle cell disease*',
      "Alzheimer's disease*",
      'Amyotrophic lateral sclerosis*',
      'AIDS*',
      'Mitochondrial disease',
      'Patient is in hospice program, either as inpatient or outpatient',
    ],
  },
  {
    summary:
      'What conditions qualify for a medical marijuana card in Louisiana?',
    details:
      'In order to qualify for a medical marijuana card, Louisianans must meet with a licensed Louisiana physician to be diagnosed with one or more of the following conditions:',
    bullets: [
      'Chronic pain associated with fibromyalgia',
      'Chronic pain associated with sickle cell disease',
      'Intractable pain',
      'Post-traumatic stress disorder (PTSD)',
      'Cancer',
      'Glaucoma',
      'Positive status for human immunodeficiency virus (HIV)',
      'Acquired immune deficiency syndrome (AIDS)',
      'Cachexia or wasting syndrome',
      'Seizure disorders',
      'Epilepsy',
      'Spasticity',
      'Severe muscle spasms',
      "Crohn's disease",
      'Muscular dystrophy',
      'Multiple sclerosis',
      'Traumatic brain injury',
      'A concussion diagnosed by a physician',
      'Any condition for which a patient is receiving hospice care or palliative care',
      'Any of the following conditions associated with autism spectrum disorder:\n \
1. Repetitive or self-stimulatory behavior of such severity that the physical health of the person with autism is jeopardized.\n \
2. Avoidance of others or inability to communicate of such severity that the physical health of the person with autism is jeopardized.\n \
3. Self-injuring behavior.\n \
4. Physically aggressive or destructive behavior.',
      'Other',
    ],
  },
  {
    summary:
      'What conditions qualify for a medical marijuana card in Missouri?',
    details:
      'In order to qualify for a medical marijuana card, Missourians must meet with a licensed Missouri physician to be diagnosed with one or more of the following conditions:',
    bullets: [
      'Cancer',
      'Epilepsy',
      'Glaucoma',
      'Intractable migraines unresponsive to other treatment',
      "A chronic medical condition that causes severe, persistent pain or persistent muscle spasms, including but not limited to ailments such as multiple sclerosis, seizures, Parkinson's disease, and Tourette's syndrome",
      'Debilitating psychiatric disorders, including, but not limited to, post-traumatic stress order if diagnosed by a state-licensed psychiatrist',
      'Human immunodeficiency virus or acquired immune deficiency syndrome',
      'A chronic medical condition that is normally treated with a prescription medication that could lead to physical or psychological dependence, when a physician determines that medical use of marijuana could be effective in treating that condition and would serve as a safer alternative to the prescription medication',
      'A terminal illness',
      "Chronic, debilitating pain, or other medical condition, including, but not limited to, hepatitis C, amyotrophic lateral sclerosis (ALS), inflammatory bowel disease, Crohn's disease, Huntington's disease, autism, neuropathies, sickle cell anemia, agitation of Alzheimer's disease, cachexia, and wasting syndrome",
    ],
  },
  {
    summary: 'What conditions qualify for a medical marijuana card in Ohio?',
    details:
      'In order to qualify for a medical marijuana card, Ohioans must meet with a licensed Ohio physician to be diagnosed with one or more of the following conditions:',
    bullets: [
      'AIDS',
      'Amyotrophic lateral sclerosis',
      "Alzheimer's disease",
      'Cachexia',
      'Cancer',
      'Chronic traumatic encephalopathy',
      "Crohn's disease",
      'Epilepsy or another seizure disorder',
      'Fibromyalgia',
      'Glaucoma',
      'Hepatitis C',
      "Huntington's disease",
      'Inflammatory bowel disease',
      'Multiple sclerosis',
      'Pain that is either chronic and severe or intractable, like arthritis chronic migraines and complex region pain syndrome',
      "Parkinson's disease",
      'Positive status for HIV',
      'Post-traumatic stress disorder',
      'Sickle cell anemia',
      'Spasticity',
      'Spinal cord disease or injury',
      'Terminal illness',
      'Tourette syndrome',
      'Traumatic brain injury',
      'Ulcerative colitis',
    ],
  },
  {
    summary:
      'What conditions qualify for a medical marijuana card in Oklahoma?',
    details:
      'Unlike most states, Oklahoma does not have a specific list of qualifying conditions. Any medical condition that is either chronic or debilitating is covered.',
  },
  {
    summary:
      'What conditions qualify for a medical marijuana card in Pennsylvania?',
    details:
      'In order to qualify for a medical marijuana card, Pennsylvanians must meet with a licensed Pennsylvania physician to be diagnosed with one or more of the following conditions:',
    bullets: [
      'Pain that is either chronic and severe or intractable',
      'Dyskinetic and spastic movement disorders',
      'Amyotrophic lateral sclerosis (ALS)',
      'Cancer, including remission therapy',
      'Glaucoma',
      'Positive status for HIV',
      'Hepatitis C',
      'Anxiety Disorders',
      'Autism',
      "Crohn's disease",
      'Inflammatory bowel disease (IBD)',
      'Ulcerative colitis',
      'Fibromyalgia',
      'Multiple sclerosis (MS)',
      "Parkinson's disease",
      'Post-traumatic stress disorder (PTSD)',
      'Sickle cell anemia',
      'Traumatic brain injury',
      'Spinal cord disease or injury',
      "Tourette's syndrome",
      'Damage to the nervous tissue of the central nervous system (brain-spinal cord) with objective neurological indication of intractable spasticity, and other associated neuropathies',
    ],
  },
  {
    summary: 'Is my greenpass valid in every state? ',
    details:
      'Your greenpass is valid in the state through which you apply and each state has different regulations with regards to licensure. Currently, California, Georgia, Louisiana, Missouri, Ohio, Oklahoma, and Pennsylvania do not offer reciprocity with medical cannabis cards from other states.',
  },
  {
    summary: 'How long is my medical marijuana card valid?',
    details:
      "Missouri medical marijuana cards are valid for 3 years from approval date. Georgia and Oklahoma medical marijuana cards are valid for 2 years from approval date. California, Louisiana, Ohio, and Pennsylvania medical marijuana cards are valid for 1 year from approval date. In anticipation of your card's expiration, please login to your greenpass account and we will schedule a doctor's consultation to renew your recommendation. Don't worry, we'll send you a reminder!",
  },
  {
    summary: 'How much does it cost to get a medical marijuana card?',
    details:
      "Your medical marijuana journey with greenpass will be as quick and easy as possible. greenpass doctor's consultations start at $99, which will only be charged if you pre-qualify for medical marijuana based on the information you provide.",
  },
];

export default function ContactAccordion() {
  return (
    <div style={{ padding: '2em 2em' }}>
      {accordionContent.map((accordion, index) => (
        <Accordion key={index}>
          <AccordionSummary
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, .125)',
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6" style={{ color: '#1C6F52' }}>
              {accordion.summary}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography variant="subtitle2">{accordion.details}</Typography>
            {accordion.bullets && (
              <List>
                {accordion.bullets.map((bulletItem, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <FiberManualRecordIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={bulletItem}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
