import PropTypes from "prop-types";
import { Alert, AlertTitle } from "@material-ui/lab";

const ErrorBox = ({ errorMsg, clearErrorMsg }) => {
  if (!errorMsg) return null;

  return (
    <div style={{ width: "100%", marginTop: "1em", marginBottom: "1em" }}>
      <Alert severity="error" onClose={clearErrorMsg}>
        <AlertTitle>Error</AlertTitle>
        {errorMsg}
      </Alert>
    </div>
  );
};

ErrorBox.propTypes = {
  errorMsg: PropTypes.string,
  clearErrorMsg: PropTypes.func,
};

export default ErrorBox;
