import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Grid, Typography } from '@material-ui/core';
import { selectUserState } from '../../redux/slices/userSlice';
import FormFieldControl from './controls/FormField';
import FormDateTimeControl from './controls/FormDateTimeControl';
import FormSelectControl from './controls/FormSelect';
import FormMaskField from './controls/FormMaskField';
import TermsDialog from './TermsDialog';
import states from './states';

const sexes = [
  { value: '', label: 'None' },
  { value: 0, label: 'Male' },
  { value: 1, label: 'Female' },
  { value: 2, label: 'Prefer not to answer' },
];

const DemographicsForm = (props) => {
  const {
    formField: {
      address,
      birthDate,
      city,
      email,
      firstName,
      lastName,
      licenseId,
      middleName,
      phoneNumber,
      sex,
      ssn,
      stateOfResidence,
      zipcode,
    },
  } = props;

  const user = useSelector(selectUserState);
  const { control } = useFormContext();

  // if (user.portalMemberId) {
  //   return (
  //     <>
  //       <Typography
  //         align="center"
  //         paragraph
  //         style={{ color: '#1C6F52' }}
  //         variant="h4"
  //       >
  //         Tell Us About Yourself
  //       </Typography>
  //       <Typography variant="h6" align="center" color="textSecondary" paragraph>
  //         You have already enrolled in our portal. Please advance to the next
  //         step!
  //       </Typography>
  //     </>
  //   );
  // }

  return (
    <>
      <Typography
        align="center"
        paragraph
        style={{ color: '#1C6F52' }}
        variant="h4"
      >
        Tell Us About Yourself
      </Typography>
      <Grid container spacing={3}>
        <FormFieldControl
          autoComplete="given-name"
          control={control}
          half
          disabled
          value={user.firstName}
          label={firstName.label}
          name={firstName.name}
          required
        />
        <FormFieldControl
          autoComplete="additional-name"
          control={control}
          half
          label={middleName.label}
          name={middleName.name}
        />
        <FormFieldControl
          autoComplete="family-name"
          control={control}
          half
          disabled
          value={user.lastName}
          label={lastName.label}
          name={lastName.name}
          required
        />
        <FormFieldControl
          autoComplete="email"
          control={control}
          half
          disabled
          value={user.email}
          label={email.label}
          name={email.name}
          required
          type="email"
        />
        <FormDateTimeControl
          autoOk
          half
          autoComplete="bday"
          control={control}
          format="MM/DD/YYYY"
          initialFocusedDate={dayjs().subtract(18, 'years')}
          label={birthDate.label}
          name={birthDate.name}
          required
        />
        <FormSelectControl
          autoComplete="sex"
          control={control}
          half
          label={sex.label}
          labelid="sex-label"
          menuItems={sexes}
          name={sex.name}
          required
        />
        <FormFieldControl
          required
          control={control}
          name={address.name}
          label={address.label}
          autoComplete="address-line1"
        />
        <FormFieldControl
          half
          required
          control={control}
          name={city.name}
          label={city.label}
          autoComplete="address-level2"
        />
        <FormSelectControl
          half
          required
          control={control}
          name={stateOfResidence.name}
          label={stateOfResidence.label}
          labelid="state-of-residence-label"
          autoComplete="address-level1"
          menuItems={states}
          SelectProps={{
            MenuProps: {
              style: {
                maxHeight: 400,
              },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            },
          }}
        />
        <FormFieldControl
          half
          required
          control={control}
          name={zipcode.name}
          label={zipcode.label}
          autoComplete="shipping postal-code"
        />
        <FormMaskField
          required
          half
          control={control}
          mask="(999) 999-9999"
          name={phoneNumber.name}
          label={phoneNumber.label}
          autoComplete="tel"
        />
        {user.stateForApplication === 'MO' && (
          <FormMaskField
            half
            required
            control={control}
            mask="999-99-9999"
            name={ssn.name}
            label={ssn.label}
          />
        )}
        {user.stateForApplication === 'OH' && (
          <FormFieldControl
            control={control}
            half
            required
            label={licenseId.label}
            name={licenseId.name}
          />
        )}
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <TermsDialog />
        </Grid>
      </Grid>
    </>
  );
};

DemographicsForm.propTypes = {
  formField: PropTypes.shape({
    address: PropTypes.object,
    birthDate: PropTypes.object,
    city: PropTypes.object,
    email: PropTypes.object,
    firstName: PropTypes.object,
    lastName: PropTypes.object,
    licenseId: PropTypes.object,
    middleName: PropTypes.object,
    phoneNumber: PropTypes.object,
    sex: PropTypes.object,
    ssn: PropTypes.object,
    stateOfResidence: PropTypes.object,
    zipcode: PropTypes.object,
  }),
};

export default DemographicsForm;
