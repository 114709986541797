import { Paper, Typography } from "@material-ui/core";
import useStyles from "./styles";

const ConsultCompleted = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography variant="h2" align="center" style={{ color: "#1C6F52" }}>
        Thank you!
      </Typography>
      <Typography
        variant="h6"
        align="center"
        style={{ paddingTop: "1em", color: "#707070" }}
      >
        Your virtual consultation has concluded.
      </Typography>
      <Typography
        align="center"
        style={{
          paddingTop: "1em",
          color: "#707070",
        }}
      >
        Keep an eye on your inbox! We will send you an email with the outcome of
        your consultation within the next 24 to 48 hours.
      </Typography>
    </Paper>
  );
};

export default ConsultCompleted;
