const qualifyingConditionsByState = {
  //////////////////////////////////////////////////////////////
  // California Qualifying Conditions
  //////////////////////////////////////////////////////////////
  CA: [
    {
      name: 'chronicPain',
      label: 'Chronic pain',
    },
    {
      name: 'arthritis',
      label: 'Arthritis',
    },
    {
      name: 'migraines',
      label: 'Migraines',
    },
    {
      name: 'seizures',
      label: 'Seizures',
    },
    {
      name: 'cancer',
      label: 'Cancer',
    },
    {
      name: 'muscleSpasms',
      label: 'Persistent muscle spasms',
    },
    {
      name: 'glaucoma',
      label: 'Glaucoma',
    },
    {
      name: 'anorexia',
      label: 'Anorexia',
    },
    {
      name: 'nausea',
      label: 'Severe nausea',
    },
    {
      name: 'hivaids',
      label: 'HIV/AIDS',
    },
    {
      name: 'chronicSymptoms',
      label:
        "Any type of chronic or ongoing medical symptom that either:\
      (A) “Substantially limits the ability of the person to conduct one or more major life activities as defined in the federal Americans with Disabilities Act of 1990” (Public Law 101-336).\
      (B) If not alleviated, may cause serious harm to the patient's safety or physical or mental health.",
    },
    {
      name: 'cachexia',
      label: 'Cachexia (Wasting syndrome)',
    },
  ],
  //////////////////////////////////////////////////////////////
  // Georgia Qualifying Conditions
  //////////////////////////////////////////////////////////////
  GA: [
    {
      name: 'intractablePain',
      label: 'Intractable pain',
    },
    {
      name: 'epidermolysis',
      label: 'Epidermolysis bullosa',
    },
    {
      name: 'crohns',
      label: "Crohn's disease",
    },
    {
      name: 'parkinsons',
      label: "Parkinson's disease",
    },
    {
      name: 'ms',
      label: 'Multiple sclerosis',
    },
    {
      name: 'peripheralNeuropathy',
      label: 'Peripheral neuropathy',
    },
    {
      name: 'tourettes',
      label: "Tourette's syndrome",
    },
    {
      name: 'sickleCell',
      label: 'Sickle cell disease',
    },
    {
      name: 'autism',
      label: 'Autism spectrum disorder',
    },
    {
      name: 'als',
      label: 'Amyotrophic lateral sclerosis',
    },
    {
      name: 'seizures',
      label:
        'Seizure disorders related to diagnosis of epilepsy or trauma related head injuries',
    },
    {
      name: 'ptsd',
      label:
        'Post-traumatic stress disorder (PTSD) resulting from direct exposure to or witnessing of a trauma',
    },
    {
      name: 'alzheimers',
      label: "Alzheimer's disease",
    },
    {
      name: 'aids',
      label: 'AIDS',
    },
    {
      name: 'cancer',
      label:
        'Cancer, when treatment produces related wasting illness or recalcitrant nausea and vomiting',
    },
    {
      name: 'hospice',
      label: 'Patient is in hospice program, either as inpatient or outpatient',
    },
    {
      name: 'mitochondrial',
      label: 'Mitochondrial disease',
    },
  ],
  //////////////////////////////////////////////////////////////
  // Louisiana Qualifying Conditions
  //////////////////////////////////////////////////////////////
  LA: [
    {
      name: 'chronicPainF',
      label: 'Chronic pain associated with fibromyalgia',
    },
    {
      name: 'chronicPainS',
      label: 'Chronic pain associated with sickle cell disease',
    },
    {
      name: 'intratactablePain',
      label: 'Intractable pain',
    },
    {
      name: 'tbi',
      label: 'Traumatic brain injury',
    },
    {
      name: 'ptsd',
      label: 'Post-traumatic stress disorder (PTSD)',
    },
    {
      name: 'concussion',
      label: 'A concussion diagnosed by a physician',
    },
    {
      name: 'cancer',
      label: 'Cancer',
    },
    {
      name: 'glaucoma',
      label: 'Glaucoma',
    },
    {
      name: 'hiv',
      label: 'Positive status for human immunodeficiency virus (HIV)',
    },
    {
      name: 'aids',
      label: 'Acquired immune deficiency syndrome (AIDS)',
    },
    {
      name: 'cachexia',
      label: 'Cachexia or wasting syndrome',
    },
    {
      name: 'seizures',
      label: 'Seizure disorders',
    },
    {
      name: 'epilepsy',
      label: 'Epilepsy',
    },
    {
      name: 'spasticity',
      label: 'Spasticity',
    },
    {
      name: 'severeSpasms',
      label: 'Severe muscle spasms',
    },
    {
      name: 'crohns',
      label: "Crohn's disease",
    },
    {
      name: 'muscularDystrophy',
      label: 'Muscular dystrophy',
    },
    {
      name: 'multipleSclerosis',
      label: 'Multiple sclerosis',
    },
    {
      name: 'any',
      label:
        'Any of the following conditions associated with autism spectrum disorder: (A) Repetitive or self-stimulatory behavior of such severity that the physical health of the person with autism is jeopardized. (B) Avoidance of others or inability to communicate of such severity that the physical health of the person with autism is jeopardized. (C) Self-injuring behavior. (D) Physically aggressive or destructive behavior.',
    },
    {
      name: 'hospice',
      label:
        'Any condition for which a patient is receiving hospice care or palliative care',
    },
    {
      name: 'other',
      label: 'Other',
    },
  ],
  //////////////////////////////////////////////////////////////
  // Missouri Qualifying Conditions
  //////////////////////////////////////////////////////////////
  MO: [
    {
      name: 'migraines',
      label: 'Intractable migraines',
    },
    {
      name: 'chronicDebilitation',
      label: 'A chronic, debilitating or other medical condition',
    },
    {
      name: 'cancer',
      label: 'Cancer',
    },
    {
      name: 'epilepsy',
      label: 'Epilepsy',
    },
    {
      name: 'glaucoma',
      label: 'Glaucoma',
    },
    {
      name: 'ms',
      label: 'Multiple sclerosis',
    },
    {
      name: 'parkinsons',
      label: "Parkinson's disease",
    },
    {
      name: 'tourettes',
      label: "Tourette's syndrome",
    },
    {
      name: 'seizures',
      label: 'Seizures',
    },
    {
      name: 'ptsd',
      label:
        'Post-traumatic stress order, if diagnosed by a state licensed psychiatrist',
    },
    {
      name: 'hiv',
      label: 'Human immunodeficiency virus',
    },
    {
      name: 'aids',
      label: 'Acquired immune deficiency syndrome',
    },
    {
      name: 'terminal',
      label: 'A terminal illness',
    },
    {
      name: 'hepc',
      label: 'Hepatitis C',
    },
    {
      name: 'als',
      label: 'Amyotrophic lateral sclerosis',
    },
    {
      name: 'ibs',
      label: 'Inflammatory bowel disease (IBS)',
    },
    {
      name: 'crohns',
      label: "Crohn's disease, Huntington's disease",
    },
    {
      name: 'autism',
      label: 'Autism, neuropathies',
    },
    {
      name: 'sickleCell',
      label: 'Sickle cell anemia',
    },
    {
      name: 'alzheimers',
      label: "Agitation of Alzheimer's disease",
    },
    {
      name: 'cachexia',
      label: 'Cachexia',
    },
    {
      name: 'wasting',
      label: 'Wasting syndrome',
    },
    {
      name: 'chronicPain',
      label:
        'A chronic medical condition that causes severe, persistent pain or persistent muscle spasms; or a chronic or debilitating medical condition that is normally treated with a prescription medications that could lead to physical or psychological dependence',
    },
  ],
  //////////////////////////////////////////////////////////////
  // Ohio Qualifying Conditions
  //////////////////////////////////////////////////////////////
  OH: [
    {
      name: 'chronicpain',
      label: 'Pain that is either: (i) chronic and severe or (ii) intractable',
    },
    {
      name: 'ibs',
      label: 'Inflammatory bowel disease (IBS)',
    },
    {
      name: 'fibromyalgia',
      label: 'Fibromyalgia',
    },
    {
      name: 'glaucoma',
      label: 'Glaucoma',
    },
    {
      name: 'cancer',
      label: 'Cancer',
    },
    {
      name: 'epilepsy',
      label: 'Epilepsy',
    },
    { name: 'encephalopathy', label: 'Chronic traumatic encephalopathy' },
    {
      name: 'ms',
      label: 'Multiple sclerosis',
    },
    {
      name: 'parkinsons',
      label: "Parkinson's disease",
    },
    {
      name: 'tourettes',
      label: "Tourette's syndrome",
    },
    {
      name: 'seizures',
      label: 'Seizures',
    },
    {
      name: 'hiv',
      label: 'Human immunodeficiency virus',
    },
    {
      name: 'ptsd',
      label:
        'Post-traumatic stress order, if diagnosed by a state licensed psychiatrist',
    },
    {
      name: 'aids',
      label: 'Acquired immune deficiency syndrome',
    },
    {
      name: 'hepc',
      label: 'Hepatitis C',
    },
    {
      name: 'als',
      label: 'Amyotrophic lateral sclerosis',
    },
    {
      name: 'crohns',
      label: "Crohn's disease, Huntington's disease",
    },
    {
      name: 'sicklecell',
      label: 'Sickle cell anemia',
    },
    {
      name: 'alzheimers',
      label: "Agitation of Alzheimer's disease",
    },
    {
      name: 'ulcerativeColitis',
      label: 'Ulcerative colitis',
    },
    {
      name: 'spinalInjury',
      label: 'Spinal cord disease or injury',
    },
    {
      name: 'tbi',
      label: 'Traumatic brain injury',
    },
  ],
  //////////////////////////////////////////////////////////////
  // Oklhaoma Qualifying Conditions
  //////////////////////////////////////////////////////////////
  OK: [
    {
      name: 'arthritis',
      label: 'Arthritis',
    },
    {
      name: 'chronicPain',
      label: 'Chronic pain',
    },
    {
      name: 'insomnia',
      label: 'Insomnia',
    },
    {
      name: 'migraines',
      label: 'Migraines',
    },
    {
      name: 'seizures',
      label: 'Seizures',
    },
    {
      name: 'stress',
      label: 'Stress',
    },
    {
      name: 'anxiety',
      label: 'Anxiety',
    },
    {
      name: 'cancer',
      label: 'Cancer',
    },
    {
      name: 'neuropathy',
      label: 'Neuropathy',
    },
    {
      name: 'muscleSpasms',
      label: 'Persistent muscle spasms',
    },
    {
      name: 'severeNausea',
      label: 'Severe nausea',
    },
    {
      name: 'ptsd',
      label: 'Post-traumatic stress disorder (PTSD)',
    },
    {
      name: 'hiv',
      label: 'HIV',
    },
    {
      name: 'hepc',
      label: 'Hepatitis C',
    },
    {
      name: 'glaucoma',
      label: 'Glaucoma',
    },
    {
      name: 'ibd',
      label: 'Inflammatory bowel disease',
    },
    {
      name: 'crohns',
      label: "Crohn's disease",
    },
    {
      name: 'als',
      label: 'ALS',
    },
    {
      name: 'multipleSclerosis',
      label: 'Multiple sclerosis',
    },
    {
      name: 'parkinsons',
      label: "Parkinson's disease",
    },
    {
      name: 'tourettes',
      label: "Tourette's syndrome",
    },
    {
      name: 'huntingtons',
      label: "Huntington's disease",
    },
    {
      name: 'epilepsy',
      label: 'Epilepsy',
    },
    {
      name: 'cachexia',
      label: 'Cachexia',
    },
    {
      name: 'opioidUse',
      label: 'Opioid use',
    },
    {
      name: 'anorexia',
      label: 'Anorexia',
    },
    {
      name: 'alzheimers',
      label: "Alzheimer's disease",
    },
    {
      name: 'aids',
      label: 'AIDS',
    },
    {
      name: 'other',
      label: 'Other',
    },
  ],
  //////////////////////////////////////////////////////////////
  // Pennsylvania Qualifying Conditions
  //////////////////////////////////////////////////////////////
  PA: [
    {
      name: 'pain',
      label: 'Pain that is either chronic and severe or intractable',
    },
    {
      name: 'dyskinetic',
      label: 'Dyskinetic and spastic movement disorders',
    },
    {
      name: 'als',
      label: 'Amyotrophic lateral sclerosis (ALS)',
    },
    {
      name: 'cancer',
      label: 'Cancer, including remission therapy',
    },
    {
      name: 'glaucoma',
      label: 'Glaucoma',
    },
    {
      name: 'hiv',
      label: 'Positive status for HIV',
    },
    {
      name: 'hepc',
      label: 'Hepatitis C',
    },
    {
      name: 'anxiety',
      label: 'Anxiety disorders',
    },
    {
      name: 'autism',
      label: 'Autism',
    },
    {
      name: 'crohns',
      label: "Crohn's disease",
    },
    {
      name: 'ibd',
      label: 'Inflammatory bowel disease',
    },
    {
      name: 'ptsd',
      label: 'Post-traumatic stress disorder (PTSD)',
    },
    {
      name: 'fibromyalgia',
      label: 'Fibromyalgia',
    },
    {
      name: 'ms',
      label: 'Multiple sclerosis (MS)',
    },
    {
      name: 'parkinsons',
      label: "Parkinson's disease",
    },
    {
      name: 'ulcerativeColitis',
      label: 'Ulcerative colitis',
    },
    {
      name: 'sicklecell',
      label: 'Sickle cell anemia',
    },
    {
      name: 'tbi',
      label: 'Traumatic brain injury',
    },
    {
      name: 'spinalInjury',
      label: 'Spinal cord disease or injury',
    },
    {
      name: 'tourrettes',
      label: "Tourette's syndrome",
    },
    {
      name: 'damage',
      label:
        'Damage to the nervous tissue of the central nervous system (brain-spinal cord) with objective neurological indication of intractable spasticity, and other associated neuropathies',
    },
  ],
};

export default qualifyingConditionsByState;
