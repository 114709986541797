import { Button, Paper, Container, Typography } from "@material-ui/core";
import useStyles from "./styles";

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <Container
      component="main"
      style={{ minHeight: "60vh", marginBottom: "1em" }}
    >
      <Paper className={classes.notFoundPaper} elevation={0}>
        <Typography style={{ color: "#EFEDEA", fontSize: "25vh" }}>
          404
        </Typography>
        <Typography variant="h4" style={{ color: "#1C6F52" }}>
          Oops. Page Not Found
        </Typography>
        <Typography
          variant="h5"
          align="center"
          style={{ color: "#0D2433", paddingBottom: "1em" }}
        >
          Sorry, we can&apos;t find the page you&apos;re looking for.
        </Typography>
        <Button href="/" variant="contained">
          Back to Home
        </Button>
      </Paper>
    </Container>
  );
};

export default PageNotFound;
