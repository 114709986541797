import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    minHeight: "3em",
    minWidth: "15em",
    backgroundColor: "#2AB56E",
    "&:hover": {
      backgroundColor: "#0D2433",
    },
  },
  notFoundPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
