import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

const FormCheckboxControl = (props) => {
  const { label, name, defaultChecked } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const renderHelperText = () => {
    if (errors.termsCheckbox) {
      return (
        <FormHelperText style={{ display: "flex", justifyContent: "flex-end" }}>
          {errors.termsCheckbox.message}
        </FormHelperText>
      );
    }
  };

  return (
    <FormControl required error={!!errors[name]}>
      <FormControlLabel
        label={label}
        control={
          <Controller
            control={control}
            defaultValue={!!defaultChecked}
            name={name}
            render={({ field }) => (
              <Checkbox
                icon={<CircleUnchecked />}
                checked={field.value}
                checkedIcon={<CircleCheckedFilled color="primary" />}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        }
      />
      {renderHelperText()}
    </FormControl>
  );
};

export default FormCheckboxControl;

FormCheckboxControl.propTypes = {
  name: PropTypes.string,
  label: PropTypes.object,
  defaultChecked: PropTypes.bool,
};
