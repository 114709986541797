import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import * as api from '../../api';
import { selectUserState } from '../../redux/slices/userSlice';

const options = [
  'Google',
  'Social media',
  'Print media',
  'Word of mouth',
  'Other',
];

export default function HowDidYouHear() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const user = useSelector(selectUserState);

  function renderHelpText() {
    if (error) {
      return error.message;
    }

    if (loading) {
      return <CircularProgress size={16} />;
    }

    if (success) {
      return 'Thank you for the feedback!';
    }
  }

  async function handleChange(option) {
    try {
      setLoading(true);
      await api.howDidYouHear({
        email: user.email,
        option,
      });
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }

  // Don't display if they've already submitted feedback
  if (user.howDidYouHear) {
    return null;
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h6" align="center">
        How did you hear about us?
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        select
        onChange={(e) => handleChange(e.target.value)}
        helperText={renderHelpText()}
        error={!!error}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </Container>
  );
}
