import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Toolbar, Button, IconButton, Icon } from "@material-ui/core";
import UserMenu from "./UserMenu";
import PassIcon from "../../images/pass-icon-full.svg";
import useStyles from "./styles";
import { selectUserState } from "../../redux/slices/userSlice";

export default function Header() {
  const classes = useStyles();
  const user = useSelector(selectUserState);

  return (
    <>
      <Toolbar component="nav" variant="dense" className={classes.toolbar}>
        <IconButton className={classes.iconButton} component={Link} to={"/"}>
          <Icon className={classes.icon}>
            <img src={PassIcon} alt="Home" />
          </Icon>
        </IconButton>
        {user?.token ? (
          <div className={classes.profile}>
            <UserMenu user={user} />
          </div>
        ) : (
          <Button
            component={Link}
            to="/login"
            variant="outlined"
            className={classes.button}
          >
            Sign In
          </Button>
        )}
      </Toolbar>
    </>
  );
}
