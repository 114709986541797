import { Typography, Link } from '@material-ui/core';

export default function GeorgiaSteps() {
  return (
    <>
      <Typography style={{ paddingBottom: '1em' }}>
        Your physician has recommended you for medical cannabis relief.
        <br />
        <br />
        Here are the next steps you MUST take:
      </Typography>
      <Typography variant="h6">1. GET YOUR CARD</Typography>
      <Typography style={{ paddingBottom: '1em' }}>
        The{' '}
        <Link
          href="https://dph.georgia.gov/low-thc-oil-registry"
          target="_blank"
          rel="noopener noreferrer"
        >
          Georgia Department of Health will contact you
        </Link>{' '}
        when your card is ready for pickup. The state can take up to 15 business
        days to process your medical marijuana license; you can check on the
        status of your card here.
      </Typography>
    </>
  );
}
