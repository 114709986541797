import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";

const LoadingIndicator = ({ minHeight }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: minHeight || "200px",
      }}
    >
      <CircularProgress size={48} />
    </div>
  );
};

LoadingIndicator.propTypes = {
  minHeight: PropTypes.string,
};

export default LoadingIndicator;
