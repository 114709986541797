import {
  Button,
  Container,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ContactAccordion from "./ContactAccordion";
import useStyles from "./styles";

const Contact = () => {
  const classes = useStyles();

  const handleOnClick = () => {
    window.location.href = `mailto:info@greenpassmed.com`;
  };

  return (
    <Container component="main" maxWidth="md" style={{ minHeight: "50vh" }}>
      <Paper className={classes.paper} elevation={0}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          style={{ color: "#1C6F52", paddingTop: "1em" }}
        >
          Have a question for us?
        </Typography>
        <Typography gutterBottom align="center">
          Check out our FAQ to learn more about how greenpass can help you find
          relief.
        </Typography>
        <ContactAccordion />
        <Typography align="center">
          If you still have questions, visit your state&apos;s support website
          below, or shoot us an email. We&apos;ll get back to you within one
          business day.
        </Typography>
        <Container maxWidth="sm">
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Link
                  href="https://health.mo.gov/safety/medical-marijuana/patient-services.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Missouri Department of Health and Senior Services website
                </Link>
              }
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Link
                  href="https://medicalmarijuana.ohio.gov/patients-caregivers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ohio Medical Marijuana Control Program website
                </Link>
              }
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItem>
        </Container>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Button onClick={handleOnClick} variant="contained">
            Contact Us
          </Button>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Contact;
