import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    borderRadius: "5em",
    minHeight: "3em",
    minWidth: "15em",
    backgroundColor: "primary",
    "&:hover": {
      backgroundColor: "secondary",
    },
  },
  threeStepsCardGrid: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(800)]: {
      flexDirection: "column",
      alignItems: "center",
    },
    justifyContent: "space-between",
    alignItems: "flex-start",
    textAlign: "center",
  },
  threeStepsBox: {
    padding: "2em 0 2em",
    background: "#E5E1DC",
  },
  threeStepsImage: {
    height: "150px",
    width: "150px",
    [theme.breakpoints.down(800)]: {
      height: "100px",
      width: "100px",
    },
  },
  threeStepsGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "250px",
  },
  threeStepsImageBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    borderColor: "#1C6F52",
    [theme.breakpoints.down(800)]: {
      height: "150px",
      width: "150px",
    },
    height: "225px",
    width: "225px",
    background: "rgba(42,181,110,.12)",
  },
  threeStepsCardText: {
    [theme.breakpoints.down(800)]: {
      paddingBottom: "2em",
    },
  },
  whoWeAreContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.down(800)]: {
      padding: "2em 0 2em",
      flexDirection: "column-reverse",
      textAlign: "center",
    },
    [theme.breakpoints.up(1700)]: {
      justifyContent: "center",
    },
  },
  notFoundPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  carousel: {
    marginTop: "-5em",
  },
}));
