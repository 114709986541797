import { Grid, Container, Typography } from '@material-ui/core';
import useStyles from './styles';
import FooterSection from './FooterSection';

import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
export default function Footer() {
  const classes = useStyles();

  const social = [
    {
      name: 'Instagram',
      icon: InstagramIcon,
      url: 'https://instagram.com/greenpassmed',
      type: 'external',
    },
    {
      name: 'Twitter',
      icon: TwitterIcon,
      url: 'https://twitter.com/greenpassmed',
      type: 'external',
    },
    {
      name: 'Facebook',
      icon: FacebookIcon,
      url: 'https://facebook.com/greenpassmed',
      type: 'external',
    },
  ];

  const blog = [
    {
      name: 'Recent Posts',
      url: 'https://blog.greenpassmed.com',
      type: 'external',
    },
    {
      name: 'Archive',
      url: 'https://blog.greenpassmed.com',
      type: 'external',
    },
  ];

  const states = [
    { name: 'California', url: '/california' },
    { name: 'Georgia', url: '/georgia' },
    { name: 'Louisiana', url: '/louisiana' },
    { name: 'Missouri', url: '/missouri' },
    { name: 'Ohio', url: '/ohio' },
    { name: 'Oklahoma', url: '/oklahoma' },
    { name: 'Pennsylvania', url: '/pennsylvania' },
  ];

  const sections = [
    { name: 'Get Started', url: '/get-started' },
    { name: 'About', url: '/about' },
    { name: 'Contact', url: '/contact' },
  ];

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          color="primary"
          style={{ paddingBottom: '.5em' }}
        >
          greenpass
        </Typography>
        <Grid container spacing={4}>
          <FooterSection
            labelLink="Home"
            href="/"
            containerDirection="column"
            containerSpacing={1}
            links={sections}
          />
          <FooterSection
            label="States"
            containerDirection="column"
            containerSpacing={1}
            links={states}
          />
          <FooterSection
            label="Connect"
            containerDirection="row"
            containerSpacing={4}
            links={social}
          />
          <FooterSection
            labelURL="Blog"
            href="https://blog.greenpassmed.com/"
            containerDirection="column"
            containerSpacing={1}
            links={blog}
          />
        </Grid>
        <Container maxWidth="sm">
          <img
            style={{ maxWidth: '100%', maxHeight: '100%', marginTop: '2em' }}
            src="https://d3cli4pc8r7lwv.cloudfront.net/FooterLogos.png"
          />
        </Container>
      </Container>
    </footer>
  );
}
