import { useState } from "react";
import { useForm } from "react-hook-form";
import * as api from "../../api/index.js";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getErrorMessage } from "../../utils/helpers.js";

import FormFieldControl from "./controls/FormField.js";

import { Button, Paper, Typography, Container } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import signupModel from "../../utils/forms/model/signupModel";

import ErrorBox from "../Error/ErrorBox";
import useStyles from "./styles";

const ForgotPassword = () => {
  const classes = useStyles();
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const {
    formField: { email },
  } = signupModel;

  const signupSchema = Yup.object().shape({
    [email.name]: Yup.string().required(`${email.requiredErrorMsg}`),
  });

  const { control, handleSubmit } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(signupSchema),
  });

  const handleForgotPassword = async ({ email }) => {
    try {
      await api.sendResetPassword(email);
      setEmailSent(true);
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
      setError(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs" style={{ minHeight: "70vh" }}>
      <Paper className={classes.signinPaper} elevation={3}>
        <Typography
          variant="h5"
          style={{ color: "#1C6F52", paddingBottom: "1em" }}
        >
          Forgot Password?
        </Typography>
        <Typography style={{ paddingBottom: "1em" }}>
          An email will be sent to your account with instructions to reset your
          password. Please enter your email below.
        </Typography>
        {emailSent ? (
          <Alert severity="success">
            <AlertTitle>Email Sent</AlertTitle>
            Please check your inbox for the reset link.
          </Alert>
        ) : (
          <form
            className={classes.form}
            onSubmit={handleSubmit(handleForgotPassword)}
          >
            <FormFieldControl
              autoComplete="email"
              control={control}
              label={email.label}
              name={email.name}
              required
              type="email"
            />

            <Button type="submit" fullWidth variant="contained" color="primary">
              Reset Password
            </Button>
          </form>
        )}

        {error && (
          <ErrorBox
            errorMsg={errorMessage}
            clearErrorMsg={() => {
              setError(false);
            }}
          />
        )}
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
