import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Grid, TextField, MenuItem } from "@material-ui/core";
function FormSelectControl({ control, half, name, menuItems, ...rest }) {
  return (
    <Grid item xs={12} sm={half ? 6 : 12}>
      <Controller
        control={control}
        defaultValue={rest.defaultValue || ""}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <TextField
            fullWidth
            variant="outlined"
            {...field}
            {...rest}
            select
            helperText={error ? error.message : ""}
            error={!!error}
          >
            {menuItems.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </Grid>
  );
}

export default FormSelectControl;

FormSelectControl.propTypes = {
  control: PropTypes.object,
  half: PropTypes.bool,
  menuItems: PropTypes.array,
  name: PropTypes.string,
};
