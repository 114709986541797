import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Link, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import HowDidYouHear from './HowDidYouHear';

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(timezone);

const ConsultAccepted = ({ consultDate, waitingRoomURL }) => {
  const history = useHistory();

  function shouldShowConsultButton() {
    return (
      consultDate &&
      dayjs.utc(consultDate).local().diff(dayjs(), 'minutes') <= 30
    );
  }

  useEffect(() => {
    if (consultDate && dayjs().diff(consultDate, 'minutes') > 90) {
      history.push('consult-completed');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="h2" align="center" style={{ color: '#1C6F52' }}>
        Thanks for scheduling!
      </Typography>

      <Typography
        variant="h4"
        align="center"
        style={{ paddingTop: '1em', color: '#0D2433' }}
      >
        {dayjs
          .utc(consultDate)
          .tz(dayjs.tz.guess())
          .format('MM/DD/YYYY h:mm A z')}
      </Typography>
      <Typography
        // variant="h6"
        align="center"
        style={{
          maxWidth: '80%',
          padding: '1em 0 2em',
          color: '#707070',
        }}
      >
        30 minutes before your scheduled appointment time a link will appear.
        Click the link to join your virtual visit.
        <br />
        <br />
        If the link does not automatically appear, try refreshing your browser
        page.
      </Typography>
      <HowDidYouHear />
      {true && (
        <Typography
          variant="h5"
          align="center"
          style={{ paddingTop: '1em', color: '#0D2433' }}
        >
          <Link
            href={waitingRoomURL}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Button variant="contained"> Click Here</Button>
          </Link>
        </Typography>
      )}
    </>
  );
};

ConsultAccepted.propTypes = {
  consultDate: PropTypes.string,
  waitingRoomURL: PropTypes.string,
};

export default ConsultAccepted;
