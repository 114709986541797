import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import GetGreenpassButton from '../Common/GetGreenpassButton';
import missouriIcon from '../../images/missouriIcon.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const GetMissouri = ({ state }) => {
  const classes = useStyles();
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const states = {
    CA: {
      src: 'https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-ca-icon2.png',
      state: 'a California',
      backgroundSize: 'cover',
      backgroundImagePosition: 'center 50%',
      backgroundImageUrl:
        'https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-ca2.jpg',
    },
    GA: {
      src: 'https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-ga-icon2.png',
      state: 'a Georgia',
      backgroundSize: mediumScreen ? 'cover' : 'auto 170%',
      backgroundImagePosition: 'center 75%',
      backgroundImageUrl:
        'https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-ga2.jpg',
    },
    LA: {
      src: 'https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-la-icon2.png',
      state: 'a Louisiana',
      backgroundSize: 'cover',
      backgroundImagePosition: 'center 75%',
      boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.25)',
      backgroundImageUrl:
        'https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-la.jpg',
    },
    MO: {
      src: missouriIcon,
      state: 'a Missouri',
      backgroundSize: 'cover',
      backgroundImagePosition: 'center 75%',
      backgroundImageUrl:
        'https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-missouri.jpg',
    },
    OH: {
      src: 'https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-oh-icon2.png',
      state: 'an Ohio',
      backgroundSize: 'cover',
      backgroundImagePosition: 'top center',
      backgroundImageUrl:
        'https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-ohio.jpg',
    },
    OK: {
      src: 'https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-ok-icon2.png',
      state: 'an Oklahoma',
      backgroundSize: mediumScreen ? 'cover' : 'auto 170%',
      backgroundImagePosition: 'bottom',
      backgroundImageUrl:
        'https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-ok.jpg',
    },
    PA: {
      src: 'https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-pa-icon2.png',
      state: 'a Pennsylvania',
      backgroundSize: 'cover',
      boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.4)',
      backgroundImageUrl:
        'https://d3cli4pc8r7lwv.cloudfront.net/greenpass-medical-card-pa.jpg',
    },
  };

  const selectedState = states[state];

  return (
    <div
      style={{
        boxShadow: `${selectedState.boxShadow}`,
        backgroundImage: `url(${selectedState.backgroundImageUrl})`,
        backgroundPosition: `${selectedState.backgroundImagePosition}`,
        backgroundSize: `${selectedState.backgroundSize}`,
        backgroundRepeat: 'no-repeat',
        marginTop: '-5em',
        minHeight: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        src={selectedState.src}
        alt={selectedState.alt}
        className={classes.missouriIcon}
      />
      <Typography
        variant="h1"
        align="center"
        style={{ color: '#FFFFFF' }}
        paragraph
      >
        Apply for {selectedState.state} <br />
        medical card
      </Typography>
      <Typography
        variant="h5"
        align="center"
        style={{ color: '#FFFFFF' }}
        paragraph
      >
        relief is just a click away
      </Typography>
      <div style={{ paddingBottom: '1em' }}>
        <GetGreenpassButton />
      </div>
    </div>
  );
};

export default GetMissouri;

GetMissouri.propTypes = {
  state: PropTypes.string,
};
