import { Typography, Link } from '@material-ui/core';

export default function PennsylvaniaSteps() {
  return (
    <>
      <Typography style={{ paddingBottom: '1em' }}>
        Your physician has recommended you for medical cannabis relief.
        <br />
        <br />
        Here are the next steps you MUST take:
      </Typography>
      <Typography variant="h6">
        1. COMPLETE STATE REGISTRATION ONLINE WITH PMMP
      </Typography>
      <Typography style={{ paddingBottom: '1em' }}>
        First, wait for our team to upload your recommendation to the
        Pennsylvania registry. This may take up to 48 hours. Once you receive
        your patient ID number, login to the{' '}
        <Link
          href="https://padohmmp.custhelp.com/app/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pennsylvania patient Department of Health website
        </Link>{' '}
        and complete your registration.
      </Typography>
      <Typography variant="h6">2. GET YOUR CARD</Typography>
      <Typography style={{ paddingBottom: '1em' }}>
        The state will mail you a physical copy of your med card. Pennsylvania
        can take up to 21 days to deliver your card.
      </Typography>
    </>
  );
}
