import { Tooltip, IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const tooltipDescription =
  "As stated in the Greenpass Terms and Conditions, for a prospective patient to “Pre-Qualify” means \
    for them to meet the general criteria for medical cannabis eligibility in their state, solely based \
    on the information provided by the prospective patient. Greenpass’ Pre-Qualification process does \
    not include a professional assessment or evaluation whatsoever. Furthermore, Greenpass does not guarantee \
    that Pre-qualification will result in a physician’s Recommendation for medical cannabis or marijuana. Your \
    physician may request supplemental documentation during your consult.";

const PrequalifiedTooltip = () => {
  return (
    <Tooltip title={tooltipDescription} enterTouchDelay={0}>
      <IconButton size="small">
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default PrequalifiedTooltip;
