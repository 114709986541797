import { useSelector } from "react-redux";
import { selectUserState } from "../../redux/slices/userSlice";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

export default function GetGreenpassButton() {
  const { consultStatus } = useSelector(selectUserState);
  if (consultStatus && consultStatus > 2) {
    return (
      <Button component={Link} to="/profile" variant="contained">
        view profile
      </Button>
    );
  }
  return (
    <Button component={Link} to="/get-started" variant="contained">
      get your greenpass
    </Button>
  );
}
