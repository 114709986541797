import { Container, Typography, Link } from '@material-ui/core';
import GetGreenpassButton from '../Common/GetGreenpassButton';
import useStyles from './styles';

export default function GeorgiaSteps() {
  const classes = useStyles();
  return (
    <div
      style={{
        backgroundColor: '#206c54',
        display: 'flex',
        justifyContent: 'center',
        padding: '4em 0 4em',
      }}
    >
      <Container maxWidth="md">
        <div className={classes.stepsContainer}>
          <Typography align="left" style={{ color: '#206c54' }}>
            HOW TO
          </Typography>
          <Typography
            align="left"
            variant="h4"
            style={{ color: '#206c54', paddingBottom: '1em' }}
          >
            Get a medical card <br />
            in Georgia
          </Typography>
          <Typography variant="h6">1. SIGN UP ON OUR WEBSITE</Typography>
          <Typography style={{ paddingBottom: '1em' }}>
            Complete a quick, confidential 30 second questionnaire, about your
            medical history and wellness goals to see if you pre-qualify for a{' '}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://dph.georgia.gov/low-thc-oil-registry"
            >
              Georgia low-THC oil registry card
            </Link>
            . Please note that you must have been a Georgia resident for at
            least one year.
          </Typography>
          <Typography variant="h6">2. CHAT WITH A DOCTOR ONLINE</Typography>
          <Typography style={{ paddingBottom: '1em' }}>
            If you pre-qualify, we will process your payment through our secure
            server and you can then schedule a convenient virtual consultation
            with one of our licensed physicians. Our doctors are friendly and
            knowledgeable. Ask them anything you like. Evaluations for MMJ
            usually only take about 5 minutes.
          </Typography>
          <Typography variant="h6">
            3. WAIT FOR YOUR DOCTOR TO COMPLETE YOUR RECOMMENDATION
          </Typography>
          <Typography style={{ paddingBottom: '1em' }}>
            Once you&apos;re approved, the physician will upload your
            recommendation and waiver to the Georgia Department of Health (DPH)
            and complete your application. The state will contact you when your
            card is ready to be picked up. The state can take up to 15 business
            days to process your medical marijuana license;{' '}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://dph.georgia.gov/low-thc-oil-registry"
            >
              you can check on the status of your card here
            </Link>
            . The state fee for a low-THC oil registry card of $25 is due when
            the card is picked up.
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '2em',
            }}
          >
            <GetGreenpassButton />
          </div>
        </div>
      </Container>
    </div>
  );
}
