import { Button, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const Unsubsribed = () => {
  return (
    <div
      style={{
        minHeight: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h4" style={{ color: "#1C6F52" }}>
        You have successfully unsubscribed
      </Typography>
      <CheckCircleIcon color="primary" style={{ fontSize: 150 }} />
      <Button href="/get-started" variant="contained">
        Return home
      </Button>
    </div>
  );
};

export default Unsubsribed;
