import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Link, Typography } from "@material-ui/core";
import useStyles from "./styles";

const FooterSection = (props) => {
  const classes = useStyles();
  const {
    label,
    labelLink,
    labelURL,
    href,
    containerDirection,
    containerSpacing,
    links,
  } = props;

  return (
    <Grid item xs={12} sm={3}>
      {label && (
        <Typography color="primary" variant="h6">
          {label}
        </Typography>
      )}
      {labelLink && (
        <Link component={RouterLink} to={href} variant="h6">
          Home
        </Link>
      )}
      {labelURL && (
        <Link href={href} target="_blank" rel="noopener noreferrer">
          <Typography variant="h6">{labelURL}</Typography>
        </Link>
      )}
      <Grid
        container
        className={classes.footerGrid}
        direction={containerDirection}
        spacing={containerSpacing}
      >
        {links.map((link) => (
          <Grid item key={link.name}>
            {link.type === "external" ? (
              <Link
                href={link.url}
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.icon ? <link.icon /> : <>{link.name}</>}
              </Link>
            ) : (
              <Link
                component={RouterLink}
                to={link.url}
                className={classes.link}
              >
                {link.name}
              </Link>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
// TODO there is a much better way to alternate between text, link, and router link but I can't think right now.
FooterSection.propTypes = {
  label: PropTypes.string,
  labelLink: PropTypes.string,
  labelURL: PropTypes.string,
  href: PropTypes.string,
  containerDirection: PropTypes.oneOf(["row", "column"]),
  containerSpacing: PropTypes.number,
  links: PropTypes.array,
};

export default FooterSection;
