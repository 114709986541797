const scheduleFormModel = {
  formField: {
    firstName: {
      name: 'firstName',
      label: 'First name',
      requiredErrorMsg: 'First name is required',
    },
    middleName: {
      name: 'middleName',
      label: 'Middle name',
    },
    lastName: {
      name: 'lastName',
      label: 'Last name',
      requiredErrorMsg: 'Last name is required',
    },
    email: {
      name: 'email',
      label: 'Email',
      requiredErrorMsg: 'Email is required',
    },
    ssn: {
      name: 'ssn',
      label: 'SSN',
      invalidErrorMsg: 'Social Security Number is not valid (e.g. 000-00-0000)',
      requiredErrorMsg: 'A valid social security number is required',
    },
    licenseId: {
      name: 'licenseId',
      label: 'Drivers License #',
      invalidErrorMsg: "Driver's License Number is not valid.",
      requiredErrorMsg: "A valid driver's license number is required",
    },
    sex: {
      name: 'sex',
      label: 'Gender',
      requiredErrorMsg: 'Gender is required',
    },
    birthDate: {
      name: 'birthDate',
      label: 'Date of birth',
      requiredErrorMsg: 'Date of birth is required',
      invalidErrorMsg:
        'In order to be eligible for a doctor’s consultation, greenpass patients must be 18 years of age or older.',
    },
    address: {
      name: 'address',
      label: 'Street address',
      requiredErrorMsg: 'Street address is required',
    },
    city: {
      name: 'city',
      label: 'City',
      requiredErrorMsg: 'City is required',
    },
    stateOfResidence: {
      name: 'stateOfResidence',
      label: 'State',
      requiredErrorMsg: 'State is required',
    },
    stateForApplication: {
      name: 'stateForApplication',
      label: 'State',
      requiredErrorMsg: 'State is required',
    },
    zipcode: {
      name: 'zip',
      label: 'Zip',
      requiredErrorMsg: 'Zipcode is required',
      invalidErrorMsg: 'Zipcode is not valid (e.g. 70000)',
    },
    phoneNumber: {
      name: 'phoneNumber',
      label: 'Phone number',
      requiredErrorMsg: 'Phone number is required',
      invalidErrorMsg: 'Phone number is not valid (e.g. 111-111-1111)',
    },
    termsCheckbox: {
      name: 'termsCheckbox',
      requiredErrorMsg: 'Please agree to our terms',
    },
    qualifyingConditions: {
      name: 'qualifyingConditions',
      label: "Patient's Qualifying Conditions",
      requiredErrorMsg:
        " In order to be eligible for a doctor's consultation, greenpass patients must be experiencing one or more of the above listed Qualifying Conditions.",
    },
    knownAllergies: {
      name: 'knownAllergies',
      label: "Known Allergies (blank for 'None')",
    },
    preexistingConditions: {
      name: 'preexistingConditions',
      label: "Pre-existing Conditions (blank for 'None')",
    },
    currentMedications: {
      name: 'currentMedications',
      label: "Current Medications (blank for 'None')",
    },
    consultDateTime: {
      name: 'consultDateTime',
      requiredErrorMsg: '',
    },
  },
};

export default scheduleFormModel;
