import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import SignUp from './components/Forms/SignUpForm';
import GetStarted from './components/Stepper/GetStarted';
import Home from './components/Home/Home';
import About from './components/Home/About';
import Contact from './components/Home/Contact';
import Profile from './components/Header/Profile';
import ForgotPassword from './components/Forms/ForgotPassword';
import ResetPassword from './components/Forms/ResetPassword';
import SignIn from './components/Forms/SignIn';
import ConsultContainer from './components/Stepper/ConsultContainer';
import NotFound from './components/Error/NotFound';
import EmailVerified from './components/Email/EmailVerified';
import Unsubscribed from './components/Email/Unsubscribed';
import StateHome from './components/States/Home';
import { selectUserState } from './redux/slices/userSlice';
import ConsultCompleted from './components/Stepper/ConsultCompleted';

export default function Routes() {
  const user = useSelector(selectUserState);
  const isLoggedIn = localStorage.getItem('greenpassUser') || user?.token;
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/about" exact component={About} />
      <Route path="/contact" exact component={Contact} />
      <Route exact path="/get-started">
        {isLoggedIn ? <GetStarted /> : <Redirect to="/login" />}
      </Route>
      <Route exact path="/login">
        {!isLoggedIn ? <SignIn /> : <Redirect to="/" />}
      </Route>
      <Route exact path="/california">
        <StateHome state={'CA'} />
      </Route>
      <Route exact path="/georgia">
        <StateHome state={'GA'} />
      </Route>
      <Route exact path="/louisiana">
        <StateHome state={'LA'} />
      </Route>
      <Route exact path="/missouri">
        <StateHome state={'MO'} />
      </Route>
      <Route exact path="/ohio">
        <StateHome state={'OH'} />
      </Route>
      <Route exact path="/oklahoma">
        <StateHome state={'OK'} />
      </Route>
      <Route exact path="/pennsylvania">
        <StateHome state={'PA'} />
      </Route>
      <Route exact path="/profile">
        {isLoggedIn ? <Profile /> : <Redirect to="/" />}
      </Route>
      {/* <Route exact path="/waiting-room">
        {isLoggedIn ? <WaitingRoom /> : <Redirect to="/" />}
      </Route> */}
      <Route exact path="/reset" component={ForgotPassword} />
      <Route exact path="/reset/:id" component={ResetPassword} />
      <Route exact path="/scheduled-consult">
        {isLoggedIn ? <ConsultContainer /> : <Redirect to="/" />}
      </Route>
      <Route exact path="/consult-completed">
        {isLoggedIn ? <ConsultCompleted /> : <Redirect to="/" />}
      </Route>
      <Route exact path="/signup">
        {!isLoggedIn ? <SignUp /> : <Redirect to="/" />}
      </Route>
      <Route exact path="/unsubscribe/:id" component={Unsubscribed} />
      <Route exact path="/verify/:id" component={EmailVerified} />
      <Route component={NotFound} />
    </Switch>
  );
}
