import { useSelector } from "react-redux";
import { Button, Typography } from "@material-ui/core";

import { resendEmail } from "../../api/index";

const VerifyEmail = () => {
  const user = useSelector((state) => state.user);

  const handleOnClick = () => {
    resendEmail(user.id);
  };

  return (
    <div
      style={{
        minHeight: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h4" style={{ color: "#1C6F52" }}>
        Verify Your Email
      </Typography>
      <Typography
        variant="h6"
        align="center"
        style={{ marginTop: "1em", marginBottom: "2em" }}
      >
        Welcome to greenpass, you&apos;re almost there, we just need to verify
        your email address.
      </Typography>
      <Typography variant="body1" align="center" style={{ color: "#707070" }}>
        Didn&apos;t get an email from us? Be sure to check your spam folder or
        click below and we will resend it.
      </Typography>
      <Button onClick={handleOnClick} variant="contained">
        resend email
      </Button>
    </div>
  );
};

export default VerifyEmail;
