import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button, Paper, Grid, Typography, Container } from "@material-ui/core";

import ErrorBox from "../Error/ErrorBox";
import FormFieldControl from "./controls/FormField";
import LoadingIndicator from "../Common/LoadingIndicator";
import signupSchema from "../../utils/forms/validation/signupSchema";
import signupModel from "../../utils/forms/model/signupModel";
import useStyles from "./styles";
import {
  clearUserError,
  selectUserState,
  signup,
} from "../../redux/slices/userSlice";

const SignUp = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const user = useSelector(selectUserState);

  const handleSignup = async ({
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
  }) => {
    if (password !== confirmPassword) {
      return setError("confirmPassword", {
        type: "manual",
        message: "Passwords do not match.",
      });
    }
    await dispatch(
      signup({ firstName, lastName, email, password, confirmPassword })
    );
    history.push("/get-started");
  };

  const {
    formField: { password, confirmPassword, email, firstName, lastName },
  } = signupModel;

  const { control, handleSubmit, setError } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(signupSchema),
  });

  return (
    <Container component="main" maxWidth="xs" style={{ minHeight: "70vh" }}>
      <Paper className={classes.paper} elevation={3}>
        <Typography
          component="h1"
          variant="h5"
          align="center"
          style={{ color: "#1C6F52", paddingBottom: "1em" }}
        >
          Sign Up to Get Started
        </Typography>
        {user?.loading ? (
          <LoadingIndicator />
        ) : (
          <form className={classes.form} onSubmit={handleSubmit(handleSignup)}>
            <Grid container spacing={2}>
              <FormFieldControl
                autoComplete="given-name"
                control={control}
                half
                label={firstName.label}
                name={firstName.name}
                required
              />
              <FormFieldControl
                autoComplete="family-name"
                control={control}
                half
                label={lastName.label}
                name={lastName.name}
                required
              />
              <FormFieldControl
                autoComplete="email"
                control={control}
                label={email.label}
                name={email.name}
                required
                type="email"
              />
              <FormFieldControl
                control={control}
                label={password.label}
                name={password.name}
                type="password"
              />
              <FormFieldControl
                control={control}
                label={confirmPassword.label}
                name={confirmPassword.name}
                type="password"
              />
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.signinButton}
            >
              Submit
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button component={Link} to="/login">
                  Already have an account? Sign in!
                </Button>
              </Grid>
            </Grid>
            {user?.error && (
              <ErrorBox
                errorMsg={user?.error}
                clearErrorMsg={() => dispatch(clearUserError())}
              />
            )}
          </form>
        )}
      </Paper>
    </Container>
  );
};

export default SignUp;
