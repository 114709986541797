import { useSelector } from 'react-redux';
import { CssBaseline, Paper } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';

import Stepper from './Stepper';
import VerifyEmail from '../Email/VerifyEmail';
import { selectUserState } from '../../redux/slices/userSlice';
import LoadingIndicator from '../Common/LoadingIndicator';
import useStyles from './styles';

export default function GetStarted() {
  const user = useSelector(selectUserState);

  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          {user?.loading ? (
            <LoadingIndicator />
          ) : (
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              {user.emailVerified ? <Stepper /> : <VerifyEmail />}
            </MuiPickersUtilsProvider>
          )}
        </Paper>
      </main>
    </>
  );
}
