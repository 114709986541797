import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import ErrorBox from '../Error/ErrorBox';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress,
} from '@material-ui/core';

import DemographicForm from '../Forms/DemographicForm';
import MedicalHistoryForm from '../Forms/MedicalHistoryForm';
import ScheduleConsultForm from '../Forms/ScheduleConsultForm';
import StateSelectForm from '../Forms/SelectYourState';
import SquareComponent from '../Square/SquareComponent';
import PaymentContext from '../../contexts/PaymentContext';

import useStyles from './styles';
import {
  portalEnrollUser,
  payForConsult,
  clearUserError,
  scheduleConsultForMember,
  setApplicationState,
} from '../../redux/slices/userSlice';

import validationSchema from '../../utils/forms/validation/scheduleFormSchema';
import scheduleFormModel from '../../utils/forms/model/scheduleFormModel';
import scheduleFormInitialValues from '../../utils/forms/scheduleFormInitialValues';

const steps = [
  'Select Your State',
  'Tell Us About Yourself',
  'Medical History',
  'Payment Details',
  'Schedule a Consult',
];

const { formField } = scheduleFormModel;

function getStepContent(step) {
  switch (step) {
    case 0:
      return <StateSelectForm formField={formField} />;
    case 1:
      return <DemographicForm formField={formField} />;
    case 2:
      return <MedicalHistoryForm formField={formField} />;
    case 3:
      return <SquareComponent />;
    case 4:
      return <ScheduleConsultForm formField={formField} />;
    default:
      throw new Error('Unknown step');
  }
}

export default function Checkout() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [card, setCard] = useState();
  const [discount, setDiscount] = useState();
  const [total, setTotal] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const currentValidationSchema = validationSchema[activeStep];

  useEffect(() => {
    if (user.consultId != null) {
      history.push('/scheduled-consult');
    } else if (user.portalMemberId != null) {
      // Advance to medical hx if user state is defined
      if (user.stateForApplication) {
        setActiveStep(2);
      } else {
        setActiveStep(0);
      }
    } else if (user.stateForApplication) {
      setActiveStep(1);
    }
    // TODO should this be here?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const scheduleConsult = async (data, user) => {
    const success = await dispatch(scheduleConsultForMember(data, user));
    if (success) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleNext = async (data) => {
    dispatch(clearUserError());
    window.scrollTo(0, 0);
    switch (activeStep) {
      case 0:
        dispatch(setApplicationState(data.stateForApplication));
        setActiveStep(activeStep + 1);
        break;
      case 1:
        if (!user.portalMemberId) {
          const success = await dispatch(portalEnrollUser(data, user));
          if (success) {
            setActiveStep(activeStep + 1);
          }
        } else {
          // don't re-enroll
          setActiveStep(activeStep + 1);
        }
        break;
      case 2:
        setActiveStep(activeStep + 1);
        break;
      case 3:
        if (!user.paymentSuccess) {
          const success = await dispatch(
            payForConsult(
              card,
              discount?.name,
              user.email,
              user.stateForApplication
            )
          );
          if (success) {
            setActiveStep(activeStep + 1);
          }
        } else {
          setActiveStep(activeStep + 1);
        }
        break;
      case 4:
        scheduleConsult(data, user);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    dispatch(clearUserError());
    setActiveStep(activeStep - 1);
  };

  function goToEndStep() {
    if (user.consultId != null) {
      history.push('/scheduled-consult');
    }
  }

  const methods = useForm({
    shouldUnregister: false,
    scheduleFormInitialValues,
    criteriaMode: 'all',
    resolver: yupResolver(currentValidationSchema),
  });
  const { handleSubmit } = methods;

  return (
    <>
      <PaymentContext.Provider
        value={{
          card,
          setCard,
          discount,
          setDiscount,
          total,
          setTotal,
        }}
      >
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label} style={{ color: '#2AB56E' }}>
              <StepLabel style={{ color: '#2AB56E' }} />
            </Step>
          ))}
        </Stepper>
        <>
          {activeStep === steps.length ? (
            goToEndStep()
          ) : (
            <>
              <FormProvider {...methods}>
                <form>
                  {getStepContent(activeStep)}
                  {user.error && (
                    <ErrorBox
                      errorMsg={user.error}
                      clearErrorMsg={() => {
                        dispatch(clearUserError());
                      }}
                    />
                  )}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} variant="contained">
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleNext)}
                    >
                      {user?.formLoading ? (
                        <CircularProgress size={24} />
                      ) : activeStep === steps.length - 1 ? (
                        'Schedule'
                      ) : (
                        'Next'
                      )}
                    </Button>
                  </div>
                </form>
              </FormProvider>
            </>
          )}
        </>
      </PaymentContext.Provider>
    </>
  );
}
