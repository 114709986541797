import { Typography } from '@material-ui/core';

export default function CaliforniaSteps() {
  return (
    <>
      <Typography style={{ paddingBottom: '1em' }}>
        Your physician has recommended you for medical cannabis relief.
        <br />
        <br />
        Here are the next steps you MUST take:
      </Typography>
      <Typography variant="h6">
        1. WAIT FOR YOUR DOCTOR TO COMPLETE YOUR RECOMMENDATION
      </Typography>
      <Typography style={{ paddingBottom: '1em' }}>
        You will receive the physician’s recommendation within 24 to 48 hours.
        You’ll use this recommendation at the dispensary - no need to register
        with the state.
      </Typography>
    </>
  );
}
