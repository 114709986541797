import * as Yup from 'yup';
import dayjs from 'dayjs';
import scheduleFormModel from '../model/scheduleFormModel';
const {
  formField: {
    address,
    birthDate,
    city,
    email,
    firstName,
    lastName,
    licenseId,
    qualifyingConditions,
    middleName,
    phoneNumber,
    sex,
    ssn,
    stateOfResidence,
    stateForApplication,
    termsCheckbox,
    zipcode,
    knownAllergies,
    preexistingConditions,
    currentMedications,
    consultDateTime,
  },
} = scheduleFormModel;

const validationSchema = [
  Yup.object().shape({
    [stateForApplication.name]: Yup.string().required(
      `${stateForApplication.requiredErrorMsg}`
    ),
  }),
  Yup.object().shape({
    [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
    [birthDate.name]: Yup.string()
      .nullable()
      .required(`${birthDate.requiredErrorMsg}`)
      .test('expDate', birthDate.invalidErrorMsg, (val) => {
        if (val) {
          // Patient must be 18 years or older
          if (dayjs(val, dayjs.ISO_8601).isValid()) {
            return dayjs().diff(val, 'years') >= 18;
          }
          return false;
        }
        return false;
      }),
    [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
    [knownAllergies.name]: Yup.string(),
    [preexistingConditions.name]: Yup.string(),
    [currentMedications.name]: Yup.string(),
    [email.name]: Yup.string(), //.required(`${email.requiredErrorMsg}`),
    [firstName.name]: Yup.string(), //.required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string(), //.required(`${lastName.requiredErrorMsg}`),
    [licenseId.name]: Yup.string().when('stateForApplication', {
      is: (state) => state === 'OH',
      then: Yup.string().required(`${licenseId.requiredErrorMsg}`),
    }),
    [middleName.name]: Yup.string(),
    [phoneNumber.name]: Yup.string()
      .required(`${phoneNumber.requiredErrorMsg}`)
      .min(8, `${phoneNumber.invalidErrorMsg}`),
    [sex.name]: Yup.string().required(`${sex.requiredErrorMsg}`),
    [ssn.name]: Yup.string(),
    [ssn.name]: Yup.string()
      .matches(
        '^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$',
        `${ssn.invalidErrorMsg}`
      )
      .when('stateForApplication', {
        is: (state) => state === 'MO',
        then: Yup.string().required(`${ssn.requiredErrorMsg}`),
      }),

    [stateOfResidence.name]: Yup.string().required(
      `${stateOfResidence.requiredErrorMsg}`
    ),
    [termsCheckbox.name]: Yup.boolean().oneOf(
      [true],
      `${termsCheckbox.requiredErrorMsg}`
    ),
    [zipcode.name]: Yup.string()
      .required(`${zipcode.requiredErrorMsg}`)
      .test(
        'len',
        `${zipcode.invalidErrorMsg}`,
        (val) => val && val.length === 5
      ),
  }),
  Yup.object().shape({
    // Validate at least one qualifying medical condition is selected
    [qualifyingConditions.name]: Yup.array()
      .min(1, `${qualifyingConditions.requiredErrorMsg}`)
      .of(Yup.string().required())
      .required(),
    [knownAllergies.name]: Yup.string(),
    [preexistingConditions.name]: Yup.string(),
    [currentMedications.name]: Yup.string(),
  }),
  Yup.object().shape({
    // no validation for payment form
  }),
  Yup.object().shape({
    // Validate a string is passed from the button array during schedule consult step
    [consultDateTime.name]: Yup.string().required(
      `${consultDateTime.requiredErrorMsg}`
    ),
  }),
];

export default validationSchema;
