import { Grid, Typography } from "@material-ui/core";

import SignupIcon from "../../images/signup-icon-line.svg";
import ConsultIcon from "../../images/consult-icon-line.svg";
import PassIcon from "../../images/pass-icon-line.svg";

import useStyles from "./styles";

const Home = () => {
  const cards = [
    {
      icon: SignupIcon,
      text: "greenpass connects you to a network of practitioners with your best interests at heart, and to a community of individuals like yourself seeking relief through medical cannabis.",
    },
    {
      icon: ConsultIcon,
      text: "greenpass is here for you at each step of your wellness journey. From your initial consultation through license renewal, greenpass provides long term access to consistent relief. And, all of your information is securely stored using our HIPAA compliant server.",
    },
    {
      icon: PassIcon,
      text: "greenpass will provide you with the most up to date information on medical cannabis across the globe. We will help you connect with local medical cannabis dispensaries that fulfill your specific needs.",
    },
  ];

  const classes = useStyles();
  return (
    <div className={classes.threeStepsBox}>
      <Typography
        variant="h4"
        align="center"
        style={{ color: "#0D2433", padding: "1em 0 1em" }}
      >
        greenpass is more than a medical cannabis consultation – <br /> we
        provide a pathway to a new wellness lifestyle.
      </Typography>
      <Grid container direction="column" justifyContent="center" spacing={4}>
        {cards.map((card) => (
          <Grid
            item
            container
            key={card.title}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              className={classes.threeStepsImageBox}
              style={{ height: "175px", width: "175px" }}
            >
              <img
                src={card.icon}
                alt={card.title}
                style={{ height: "125px", width: "125px" }}
              />
            </Grid>
            <Grid item>
              <Typography style={{ paddingLeft: "2em", maxWidth: "600px" }}>
                {card.text}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Home;
