import { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { verifyEmail } from "../../api/index";

const EmailVerified = (props) => {
  const { id } = props.match.params;

  useEffect(() => {
    verifyEmail(id);
  }, [id]);

  return (
    <div
      style={{
        minHeight: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h4" style={{ color: "#1C6F52" }}>
        Your Email is Verified!
      </Typography>
      <CheckCircleIcon color="primary" style={{ fontSize: 150 }} />
      <Button href="/get-started" variant="contained">
        get your greenpass
      </Button>
    </div>
  );
};

EmailVerified.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

export default EmailVerified;
