import { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { useFormContext } from 'react-hook-form';
import CheckboxGroup from './controls/FormCheckboxGroup';
import FormFieldControl from './controls/FormField';
import qualifyingConditionsByState from './qualifyingConditionsByState';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoadingIndicator from '../Common/LoadingIndicator';
import { selectUserState } from '../../redux/slices/userSlice';
import PrequalifiedTooltip from '../Common/PrequalifiedTooltip';

const MedicalHistoryForm = (props) => {
  const [currentState, setCurrentState] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUserState);
  const {
    formField: {
      qualifyingConditions,
      knownAllergies,
      preexistingConditions,
      currentMedications,
    },
  } = props;

  const { control } = useFormContext();
  const config = { label: 'label', name: 'name' };

  useEffect(() => {
    const loadState = async () => {
      setLoading(true);
      setCurrentState(qualifyingConditionsByState[user.stateForApplication]);
      setLoading(false);
    };
    loadState();
  }, [user.stateForApplication]);

  return (
    <>
      <Typography
        variant="h4"
        align="center"
        style={{ color: '#1C6F52' }}
        paragraph
      >
        Medical History
      </Typography>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Typography align="left">
            Please select any medical condition that applies to you that would
            pre-qualify you for medical cannabis.
            <PrequalifiedTooltip />
          </Typography>
          <CheckboxGroup
            control={control}
            label={qualifyingConditions.label}
            name={qualifyingConditions.name}
            options={currentState}
            config={config}
          />
          {user.stateForApplication === 'GA' && (
            <Typography align="right">
              * when such diagnosis is severe or end-stage
            </Typography>
          )}
          <Grid container spacing={3}>
            <FormFieldControl
              control={control}
              name={knownAllergies.name}
              label={knownAllergies.label}
            />
            <FormFieldControl
              control={control}
              name={preexistingConditions.name}
              label={preexistingConditions.label}
            />
            <FormFieldControl
              control={control}
              name={currentMedications.name}
              label={currentMedications.label}
            />
          </Grid>
        </>
      )}
    </>
  );
};

MedicalHistoryForm.propTypes = {
  formField: PropTypes.shape({
    currentMedications: PropTypes.object,
    knownAllergies: PropTypes.object,
    qualifyingConditions: PropTypes.object,
    preexistingConditions: PropTypes.object,
  }),
};

export default MedicalHistoryForm;
