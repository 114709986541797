import { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import * as api from "../../api/index.js";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getErrorMessage } from "../../utils/helpers.js";

import { Button, Grid, Paper, Typography, Container } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import signupModel from "../../utils/forms/model/signupModel";

import FormFieldControl from "./controls/FormField.js";
import ErrorBox from "../Error/ErrorBox";
import useStyles from "./styles";

const ResetPassword = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [passwordReset, setPasswordReset] = useState(false);
  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const {
    formField: { password, confirmPassword },
  } = signupModel;

  const signupSchema = Yup.object().shape({
    [confirmPassword.name]: Yup.string().oneOf(
      [Yup.ref("password"), null],
      `${confirmPassword.invalidErrorMsg}`
    ),
    [password.name]: Yup.string()
      .required(`${password.requiredErrorMsg}`)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        `${password.invalidErrorMsg}`
      ),
  });

  const { control, handleSubmit, setError } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(signupSchema),
  });

  const handleResetPassword = async ({ password, confirmPassword }) => {
    setFormError(false);
    if (password !== confirmPassword) {
      return setError("confirmPassword", {
        type: "manual",
        message: "Passwords do not match.",
      });
    }
    try {
      await api.resetPassword({ passwordResetToken: id, password });
      setPasswordReset(true);
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
      setFormError(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs" style={{ minHeight: "70vh" }}>
      <Paper className={classes.signinPaper} elevation={3}>
        <Typography
          variant="h5"
          style={{ color: "#1C6F52", paddingBottom: "1em" }}
        >
          Reset Your Password
        </Typography>
        <Typography style={{ paddingBottom: "1em" }}>
          Enter a new password below. Once submitted, you may log in to your
          account using the new password.
        </Typography>
        {passwordReset ? (
          <Alert severity="success">
            <AlertTitle>Password Reset</AlertTitle>
            Your password has successfully been reset. You may log in using the
            new password.
          </Alert>
        ) : (
          <form
            className={classes.form}
            onSubmit={handleSubmit(handleResetPassword)}
          >
            <Grid container spacing={2}>
              <FormFieldControl
                control={control}
                label={password.label}
                name={password.name}
                type="password"
              />
              <FormFieldControl
                control={control}
                label={confirmPassword.label}
                name={confirmPassword.name}
                type="password"
              />
            </Grid>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Reset Password
            </Button>
          </form>
        )}

        {formError && (
          <ErrorBox
            errorMsg={errorMessage}
            clearErrorMsg={() => {
              setFormError(false);
            }}
          />
        )}
      </Paper>
    </Container>
  );
};

ResetPassword.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
};

export default ResetPassword;
