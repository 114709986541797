import { Container, Typography, Link } from '@material-ui/core';
import GetGreenpassButton from '../Common/GetGreenpassButton';
import useStyles from './styles';

export default function OklahomaSteps() {
  const classes = useStyles();
  return (
    <div
      style={{
        backgroundColor: '#206c54',
        display: 'flex',
        justifyContent: 'center',
        padding: '4em 0 4em',
      }}
    >
      <Container maxWidth="md">
        <div className={classes.stepsContainer}>
          <Typography align="left" style={{ color: '#206c54' }}>
            HOW TO
          </Typography>
          <Typography
            align="left"
            variant="h4"
            style={{ color: '#206c54', paddingBottom: '1em' }}
          >
            Get a medical card <br />
            in Oklahoma
          </Typography>
          <Typography variant="h6">1. SIGN UP ON OUR WEBSITE</Typography>
          <Typography style={{ paddingBottom: '1em' }}>
            Complete a quick, confidential 30 second questionnaire, about your
            medical history and wellness goals to see if you pre-qualify for a
            Louisiana MMJ card.
          </Typography>
          <Typography variant="h6">2. CHAT WITH A DOCTOR ONLINE</Typography>
          <Typography style={{ paddingBottom: '1em' }}>
            If you pre-qualify, we will process your payment through our secure
            server and you can then schedule a convenient virtual consultation
            with one of our licensed physicians. Our doctors are friendly and
            knowledgeable. Ask them anything you like. Evaluations for MMJ
            usually only take about 5 minutes.
          </Typography>
          <Typography variant="h6">
            3. WAIT FOR YOUR DOCTOR TO COMPLETE YOUR RECOMMENDATION
          </Typography>
          <Typography style={{ paddingBottom: '1em' }}>
            Once you&apos;re approved, you&apos;ll receive the physician&apos;s
            recommendation within 24 to 48 hours. You&apos;ll use this
            recommendation to{' '}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://oklahoma.gov/omma/apply.html"
            >
              register with the Oklahoma Department of Health
            </Link>
            .
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '2em',
            }}
          >
            <GetGreenpassButton />
          </div>
        </div>
      </Container>
    </div>
  );
}
