import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import GetGreenpassButton from "../Common/GetGreenpassButton";
import useStyles from "./styles";

const listItems = [
  "No matter where you live, work, or play, greenpass is there for you.",
  "All of your information is securely stored using our network's HIPAA compliant server.",
  "greenpass will provide you with the most up to date information on medical cannabis across the globe.",
  "We will help you connect with local medical cannabis dispensaries that fulfill your specific needs.",
];

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.whoWeAreContainer}>
      <div style={{ maxWidth: "500px" }}>
        <img
          src="https://d3cli4pc8r7lwv.cloudfront.net/greenpass-get-a-medical-card-online.png"
          alt="Greenpass Phone"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </div>
      <div className="invisible" style={{ width: "200px" }} />
      <div
        style={{
          maxWidth: "600px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          style={{ color: "#0D2433" }}
          variant="h3"
          align="center"
          paragraph
        >
          Get your greenpass
        </Typography>
        <Typography style={{ color: "#0D2433" }} paragraph>
          <List>
            {listItems.map((listItem, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={listItem}
                  primaryTypographyProps={{ variant: "h5" }}
                />
              </ListItem>
            ))}
          </List>
        </Typography>
        <GetGreenpassButton />
      </div>
    </div>
  );
};

export default Home;
