import scheduleFormModel from './model/scheduleFormModel';
const {
  formField: {
    address,
    birthDate,
    city,
    email,
    firstName,
    lastName,
    middleName,
    phoneNumber,
    sex,
    stateForApplication,
    stateOfResidence,
    termsCheckbox,
    zipcode,
  },
} = scheduleFormModel;

const scheduleFormInitialValues = {
  [address.name]: '',
  [birthDate.name]: '',
  [city.name]: '',
  [email.name]: '',
  [firstName.name]: '',
  [lastName.name]: '',
  [middleName.name]: '',
  [phoneNumber.name]: '',
  [sex.name]: '',
  [stateOfResidence.name]: '',
  [stateForApplication.name]: '',
  [termsCheckbox.name]: false,
  [zipcode.name]: '',
};

export default scheduleFormInitialValues;
