import * as Yup from "yup";
import signupModel from "../model/signupModel";
const {
  formField: { confirmPassword, email, firstName, lastName, password },
} = signupModel;

const signupSchema = Yup.object().shape({
  [confirmPassword.name]: Yup.string().oneOf(
    [Yup.ref("password"), null],
    `${confirmPassword.invalidErrorMsg}`
  ),
  [email.name]: Yup.string().required(`${email.requiredErrorMsg}`),
  [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
  [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
  [password.name]: Yup.string()
    .required(`${password.requiredErrorMsg}`)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      `${password.invalidErrorMsg}`
    ),
});

export default signupSchema;
