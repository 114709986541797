import { Typography, Link } from '@material-ui/core';

export default function MissouriSteps() {
  return (
    <>
      <Typography style={{ paddingBottom: '1em' }}>
        Your physician has recommended you for medical cannabis relief.
        <br />
        <br />
        Here are the next steps you MUST take:
      </Typography>
      <Typography variant="h6">1. REGISTER ONLINE WITH DHSS</Typography>
      <Typography style={{ paddingBottom: '1em' }}>
        First{' '}
        <Link
          href="https://mo-public.mycomplia.com/#!/mo/register"
          target="_blank"
          rel="noopener noreferrer"
        >
          register as a new patient
        </Link>{' '}
        in the state of Missouri. In the next 48 hours, your doctor&apos;s
        recommendation will be connected to your registration based on your name
        and social security number. If your recommendation does not appear,
        please check back later.
      </Typography>
      <Typography variant="h6">2. CHECK YOUR EMAIL</Typography>
      <Typography style={{ paddingBottom: '1em' }}>
        The Missouri DHSS will approve or deny your application within 30 days.
        If you are approved, the state will send a digital file of your patient
        ID card via email.
      </Typography>
      <Typography variant="h6">3. PRINT YOUR PATIENT ID CARD</Typography>
      <Typography style={{ paddingBottom: '1em' }}>
        Be sure to print it out! Missourians will need to present a physical
        copy in order to purchase medical marijuana at a dispensary.
      </Typography>
    </>
  );
}
