import { Typography } from '@material-ui/core';

export default function LousianaSteps() {
  return (
    <>
      <Typography style={{ paddingBottom: '1em' }}>
        Your physician has recommended you for medical cannabis relief.
        <br />
        <br />
        Here are the next steps you MUST take:
      </Typography>
      <Typography variant="h6">
        1. WAIT FOR YOUR DOCTOR TO COMPLETE YOUR RECOMMENDATION
      </Typography>
      <Typography style={{ paddingBottom: '1em' }}>
        We will email you when your recommendation has been submitted directly
        to the dispensary of your choice. Make sure to bring your state issued
        driver&apos;s license or identification card when visiting the
        dispensary.
      </Typography>
    </>
  );
}
