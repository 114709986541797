import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../redux/slices/userSlice';
import { Grid, Typography } from '@material-ui/core/';
import PaymentContext from '../../contexts/PaymentContext';
import LoadingIndicator from '../Common/LoadingIndicator';
import OrderSummaryLineItem from './OrderSummaryLineItem';
import ErrorBox from '../Error/ErrorBox';
import * as api from '../../api';

const locationId = process.env.REACT_APP_SQ_LOCATION_ID;

const OrderSummary = () => {
  const user = useSelector(selectUserState);
  const stateForApplication = user.stateForApplication;
  const { discount, total, setTotal } = useContext(PaymentContext);
  const [loading, setLoading] = useState();
  const [lineItem, setLineItem] = useState();
  const [serviceCharge, setServiceCharge] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    async function calculateOrder() {
      setLoading(true);
      // TODO Be more clever about how this loads in
      if (!lineItem) {
        try {
          const { data } = await api.calculateOrder({
            state: stateForApplication,
            locationId,
          });
          // divide by 100 for currency
          setLineItem(data.order.lineItem);
          setServiceCharge(data.order.serviceCharge);
          setTotal(data.order.total);
        } catch (e) {
          setError(e.message);
        }
      }
      setLoading(false);
    }

    calculateOrder();
  }, [lineItem, setTotal, stateForApplication]);

  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  if (error) {
    return <ErrorBox message={'Thing'} />;
  }

  if (loading) {
    return <LoadingIndicator minHeight="100px" />;
  }

  return (
    <>
      <Typography variant="h6" align="left" style={{ color: '#1C6F52' }}>
        Order Summary
      </Typography>
      <Grid container spacing={1}>
        <OrderSummaryLineItem width={9}>{lineItem?.name}</OrderSummaryLineItem>
        <OrderSummaryLineItem width={3}>
          {formatter.format(lineItem?.amount / 100)}
        </OrderSummaryLineItem>
        {discount?.name && (
          <>
            <OrderSummaryLineItem width={9}>
              Discount ({discount?.name})
            </OrderSummaryLineItem>
            <OrderSummaryLineItem width={3} color="#2AB56E">
              - {formatter.format(discount?.amount / 100)}
            </OrderSummaryLineItem>
          </>
        )}
        <OrderSummaryLineItem width={9}>
          {serviceCharge?.name}
        </OrderSummaryLineItem>
        <OrderSummaryLineItem width={3}>
          {formatter.format(serviceCharge?.amount / 100)}
        </OrderSummaryLineItem>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{
          color: '#707070',
          borderTop: '2px dashed #707070',
          marginTop: '1em',
          paddingTop: '.5em',
        }}
      >
        <OrderSummaryLineItem width={9} variant="subtitle1">
          {total?.name}
        </OrderSummaryLineItem>
        <OrderSummaryLineItem width={3} variant="subtitle1">
          {formatter.format(total?.amount / 100)}
        </OrderSummaryLineItem>
      </Grid>
    </>
  );
};

export default OrderSummary;
