import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Grid, Typography } from '@material-ui/core';
import FormSelectControl from './controls/FormSelect';
import supportedStates from './supportedStates';

const SelectYourState = (props) => {
  const {
    formField: { stateForApplication },
  } = props;

  const { control } = useFormContext();

  return (
    <>
      <Typography
        align="center"
        paragraph
        style={{ color: '#1C6F52' }}
        variant="h4"
      >
        Select Your State
      </Typography>
      <Grid container spacing={3}>
        <FormSelectControl
          control={control}
          name={stateForApplication.name}
          label={stateForApplication.label}
          labelid="state-for-application-label"
          autoComplete="address-level1"
          menuItems={supportedStates}
          required
        />
      </Grid>
    </>
  );
};

SelectYourState.propTypes = {
  formField: PropTypes.shape({
    stateForApplication: PropTypes.object,
  }),
};

export default SelectYourState;
