import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import ErrorBox from "../Error/ErrorBox";

import {
  login,
  clearUserError,
  selectUserState,
} from "../../redux/slices/userSlice";

import useStyles from "./styles";

const validationSchema = yup.object({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Please enter your password"),
});

const SignIn = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { error } = useSelector(selectUserState);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
  });

  const handleLogin = ({ email, password }) => {
    dispatch(login({ email, password }));
  };

  return (
    <Container component="main" maxWidth="xs" style={{ minHeight: "70vh" }}>
      <Paper className={classes.signinPaper} elevation={3}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component="h1"
          variant="h5"
          style={{ color: "#1C6F52", paddingBottom: "1em" }}
        >
          Sign In
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(handleLogin)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Controller
                defaultValue=""
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    variant="outlined"
                    label="Email Address"
                    helperText={errors.email ? errors.email?.message : ""}
                    error={!!errors.email}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                defaultValue=""
                control={control}
                name="password"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    name="password"
                    label="Password"
                    type={"password"}
                    required
                    variant="outlined"
                    helperText={errors.password ? errors.password?.message : ""}
                    error={!!errors.password}
                  />
                )}
              />
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  component={Link}
                  to="/reset"
                  style={{ color: "#1C6F52" }}
                >
                  Forgot Password?
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.signinButton}
          >
            Sign In
          </Button>
        </form>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button component={Link} to="/signup" style={{ color: "#1C6F52" }}>
              Don&apos;t have an account? Sign Up!
            </Button>
          </Grid>
        </Grid>
        {error && (
          <ErrorBox
            errorMsg={error}
            clearErrorMsg={() => dispatch(clearUserError())}
          />
        )}
      </Paper>
    </Container>
  );
};

export default SignIn;
