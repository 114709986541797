import { Typography, Link } from '@material-ui/core';

export default function OklahomaSteps() {
  return (
    <>
      <Typography style={{ paddingBottom: '1em' }}>
        Your physician has recommended you for medical cannabis relief.
        <br />
        <br />
        Here are the next steps you MUST take:
      </Typography>
      <Typography variant="h6">1. DOWNLOAD YOUR RECOMMENDATION</Typography>
      <Typography style={{ paddingBottom: '1em' }}>
        Select “View Discharge Note” below to download your recommendation. This
        may take up to 48 hours. If your recommendation does not appear, please
        check back later.
      </Typography>
      <Typography variant="h6">2. REGISTER ONLINE WITH OMMA</Typography>
      <Typography style={{ paddingBottom: '1em' }}>
        The{' '}
        <Link
          href="https://oklahoma.gov/omma/apply.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register with the Oklahoma Medical Marijuana Authority
        </Link>{' '}
        using the information on your recommendation. OMMA will approve or deny
        your application within 30 days. If you are approved, the state will
        mail you a copy of your med card.
      </Typography>
    </>
  );
}
