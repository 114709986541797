import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Paper,
  Typography,
  Grid,
  Link,
  Button,
} from '@material-ui/core';
import { selectUserState } from '../../redux/slices/userSlice';
import useStyles from './styles';
import CaliforniaSteps from './States/CaliforniaSteps';
import GeorgiaSteps from './States/GeorgiaSteps';
import LouisianaSteps from './States/LouisianaSteps';
import MissouriSteps from './States/MissouriSteps';
import OhioSteps from './States/OhioSteps';
import OklahomaSteps from './States/OklahomaSteps';
import PennsylvaniaSteps from './States/PennsylvaniaSteps';
import { getConsultDetailsForMember } from '../../api';
import LoadingIndicator from '../Common/LoadingIndicator';

const Profile = () => {
  const classes = useStyles();
  const user = useSelector(selectUserState);
  const [waitingRoomURL, setWaitingRoomURL] = useState(null);

  // TODO Push to missouri if consult is complete - probably a better way to do this
  useEffect(() => {
    if (
      !user.firstName ||
      !user.lastName ||
      !user.consultId ||
      user.consultStatus != 5
    ) {
      return;
    }

    const getConsultDetails = async () => {
      try {
        const { data } = await getConsultDetailsForMember({
          portalMemberId: user.portalMemberId,
          firstName: user.firstName,
          lastName: user.lastName,
          consultId: user.consultId,
        });
        setWaitingRoomURL(data.waitingRoomURL);
      } catch (error) {
        // setError(true);
        // setLoading(false);
      }
    };

    getConsultDetails();
  }, [
    user.firstName,
    user.lastName,
    user.consultId,
    user.consultStatus,
    user.portalMemberId,
  ]);

  const getStatusDisplay = () => {
    switch (user.consultStatus) {
      case 0:
        return 'Visit Requested';
      case 2:
        return 'Scheduled';
      case 3:
        return 'Completed';
      case 4:
        return 'Denied';
      case 5:
        return 'Approved';
      default:
        return 'Yet to Schedule';
    }
  };

  const getApprovalSteps = () => {
    switch (user.stateForApplication) {
      case 'CA':
        return <CaliforniaSteps />;
      case 'GA':
        return <GeorgiaSteps />;
      case 'LA':
        return <LouisianaSteps />;
      case 'MO':
        return <MissouriSteps />;
      case 'OH':
        return <OhioSteps />;
      case 'OK':
        return <OklahomaSteps />;
      case 'PA':
        return <PennsylvaniaSteps />;
      default:
        console.warn('No approval steps for state');
        return;
    }
  };

  const viewDischargeNoteButton = () => {
    return (
      <Grid container justifyContent="center">
        <Link
          href={waitingRoomURL}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">View Discharge Note</Button>
        </Link>
      </Grid>
    );
  };

  const getPostConsultSteps = () => {
    switch (user?.consultStatus) {
      case 1:
      case 2:
      case 3:
        return (
          <Grid container justifyContent="center">
            <Button
              variant="contained"
              href="/get-started"
              style={{ minWidth: '200px' }}
            >
              Resume Consult
            </Button>
          </Grid>
        );
      case 4:
        return (
          <>
            <Typography style={{ paddingBottom: '1em' }}>
              Unfortunately, the doctor was not able to recommend medical
              cannabis for you based on your recent consultation. This is likely
              due to an inability to demonstrate a medical condition that would
              qualify you for medical cannabis in your state.
              <br />
              <br />
              If you have any outstanding questions about your consultation,
              please contact our network provider at (866) 633-4672.
            </Typography>
            {viewDischargeNoteButton()}
          </>
        );
      case 5:
        return (
          <>
            {getApprovalSteps()}
            {viewDischargeNoteButton()}
          </>
        );
      default:
        return;
    }
  };

  if (!user.firstName) {
    return <LoadingIndicator />;
  }

  return (
    <Container component="main" maxWidth="sm" style={{ minHeight: '60vh' }}>
      <Paper className={classes.paper} elevation={3}>
        <Typography
          variant="h4"
          align="center"
          style={{ color: '#1C6F52', paddingBottom: '1em' }}
        >
          Account Details
        </Typography>

        <Typography variant="h6" style={{ color: '#0D2433' }} paragraph>
          Name: {user.firstName} {user.lastName}
        </Typography>

        <Typography variant="h6" style={{ color: '#0D2433' }} paragraph>
          Email: {user.email}
        </Typography>

        {user?.consultStatus >= 0 && (
          <Typography variant="h6" style={{ color: '#0D2433' }} paragraph>
            Consult Status: {getStatusDisplay()}
          </Typography>
        )}

        {getPostConsultSteps()}
      </Paper>
    </Container>
  );
};

export default Profile;
