import { useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import { getConsultDetailsForMember } from '../../api';
import { selectUserState } from '../../redux/slices/userSlice';
import ErrorBox from '../Error/ErrorBox';
import LoadingIndicator from '../Common/LoadingIndicator';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import useStyles from './styles';
import ConsultAccepted from './ConsultAccepted';
import ConsultScheduled from './ConsultScheduled';

const ConsultContainer = () => {
  dayjs.extend(utc);
  dayjs.extend(advancedFormat);
  dayjs.extend(relativeTime);
  dayjs.extend(timezone);

  const classes = useStyles();
  const history = useHistory();

  const { consultId, consultStatus, firstName, lastName, portalMemberId } =
    useSelector(selectUserState);

  const [consultDate, setConsultDate] = useState(null);
  const [waitingRoomURL, setWaitingRoomURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // TODO Push to missouri if consult is complete - probably a better way to do this
  useLayoutEffect(() => {
    setLoading(true);
    // TODO Don't make a network req until redux loads the state - probably a better way to do this
    if (!firstName || !lastName || !consultId) {
      return;
    }
    if (consultStatus >= 3) {
      history.push('/missouri');
    }
    const getConsultDetails = async () => {
      try {
        const { data } = await getConsultDetailsForMember({
          portalMemberId,
          firstName,
          lastName,
          consultId,
        });
        setConsultDate(data.consultDateTime);
        setWaitingRoomURL(data.waitingRoomURL);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };

    getConsultDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, portalMemberId]);

  return (
    <Paper
      maxwidth="lg"
      className={classes.paper}
      style={{ alignItems: 'center' }}
      elevation={0}
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {error ? (
            <div style={{ maxwidth: '600px' }}>
              <ErrorBox errorMsg="Sorry, we're having trouble accessing your consult. Please try again later or call our support team at 866-633-4672." />
            </div>
          ) : (
            <>
              {consultStatus === 2 ? (
                <ConsultAccepted
                  waitingRoomURL={waitingRoomURL}
                  consultDate={consultDate}
                />
              ) : (
                <ConsultScheduled consultDate={consultDate} />
              )}
            </>
          )}
        </>
      )}
    </Paper>
  );
};

export default ConsultContainer;
