import PropTypes from "prop-types";
import { Typography, Grid } from "@material-ui/core";

export default function OrderSummaryLineItem({
  children,
  color,
  variant,
  width,
}) {
  return (
    <Grid item xs={width}>
      <Typography
        variant={variant || "body2"}
        align="left"
        style={{
          color: color || "#707070",
        }}
      >
        {children}
      </Typography>
    </Grid>
  );
}

OrderSummaryLineItem.propTypes = {
  children: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.string,
  width: PropTypes.string,
};
