import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import { TextField, Grid } from "@material-ui/core";
import { Controller } from "react-hook-form";

const FormMaskField = ({ control, half, name, mask, ...rest }) => {
  return (
    <Grid item xs={12} sm={half ? 6 : 12}>
      <Controller
        control={control}
        defaultValue={rest.defaultValue || ""}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <InputMask mask={mask} value={field.value} onChange={field.onChange}>
            {(inputProps) => (
              <TextField
                fullWidth
                variant="outlined"
                {...rest}
                {...inputProps}
                helperText={error ? error.message : ""}
                error={!!error}
              />
            )}
          </InputMask>
        )}
      />
    </Grid>
  );
};

export default FormMaskField;

FormMaskField.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  mask: PropTypes.string,
  half: PropTypes.bool,
};
