import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API = axios.create({ baseURL: BASE_URL });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('greenpassUser')) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem('greenpassUser')).token
    }`;
  }

  return req;
});

export const signIn = (formData) => API.post('/users/login', formData);
export const signUp = (formData) => API.post('/users/signup', formData);

// Fetch User
export const fetchUser = (id) => API.get(`/users/${id}`);

// Get Consult Details for Member
export const getConsultDetailsForMember = (consultDetailsData) =>
  API.post('/users/consult-details/', consultDetailsData);

// Edit Profile
export const updateUser = (userData) =>
  API.patch(`/users/${userData.id}`, userData);

// Forms
export const portalEnroll = (userEnrollData) =>
  API.post('/users/enroll', userEnrollData);

// Schedule Consult
export const scheduleConsultForMember = (userConsultData) =>
  API.post('/users/schedule-consult', userConsultData);

// Get Schedule Apt Times
export const getScheduleAptTimes = (userScheduleData) =>
  API.post('/users/schedule-apt-times', userScheduleData);

// Calculate order total
export const calculateOrder = (orderData) =>
  API.post('/users/calculate-order', orderData);

// Order and Pay
export const payForConsult = (paymentData) =>
  API.post('/users/payment', paymentData);

// Verify Email
export const verifyEmail = (emailVerifyToken) =>
  API.get(`/users/email/verify/${emailVerifyToken}`);

// Resend Email
export const resendEmail = (userId) =>
  API.post(`/users/email/resend/${userId}`);

// Send Reset Password Instructions
export const sendResetPassword = (email) =>
  API.post(`/users/reset-password/${email}`);

// Reset Password
export const resetPassword = (resetPasswordData) =>
  API.post(`/users/reset-password`, resetPasswordData);

// How Did You Hear
export const howDidYouHear = (howDidYouHearData) =>
  API.post('/users/how-did-you-hear', howDidYouHearData);
